import { setupEasing } from "./easing";
import { GameObjectState } from "./models/gameObject";
import { Fade, Movement } from "./models/sceneMechanicsDefinition";

export interface Animation {
    startTime: number;
    endTime: number;
    onTick: (/** A number between 0 and 1 indicating how much of the animation has completed */progress: number) => void;
    onFinal: () => void;
}

export function setupMove(gameObject: GameObjectState, props: Movement, currentTime: number): Animation {
    const xStart = gameObject.xPos;
    const yStart = gameObject.yPos;
    const dx = props.to.x - xStart;
    const dy = props.to.y - yStart;
    const distance = Math.sqrt(Math.pow(Math.abs(dx), 2) + Math.pow(Math.abs(dy), 2));
    const duration = distance / props.speed;

    const easing = setupEasing(props.easing);

    const onTick = (progress: number) => {
        gameObject.xPos = xStart + dx * easing(progress);
        gameObject.yPos = yStart + dy * easing(progress);
    };

    const onFinal = () => {
        gameObject.xPos = props.to.x;
        gameObject.yPos = props.to.y;
    };

    return {
        startTime: currentTime,
        endTime: currentTime + duration,
        onTick,
        onFinal
    };
}

export function setupMoveParabola(gameObject: GameObjectState, props: Movement, currentTime: number): Animation {
    const xStart = gameObject.xPos;
    const dx = props.to.x - xStart;

    const distance = 1258
    const duration = (distance / 50) / props.speed;

    const easing = setupEasing(props.easing);

    const onTick = (progress: number) => {
        gameObject.xPos = Math.round((xStart + dx * easing(progress)) * 1000) / 1000;
        console.log('xPos', gameObject.xPos)
        // gameObject.yPos = (gameObject.xPos^2)+50
        gameObject.yPos = (Math.pow(gameObject.xPos - 50, 2)) / (30)
        console.log('yPos', gameObject.yPos)

    };

    const onFinal = () => {
        gameObject.xPos = props.to.x;
        gameObject.yPos = props.to.y;
    };

    return {
        startTime: currentTime,
        endTime: currentTime + duration * 2,
        onTick,
        onFinal
    };
}

export function setupFade(gameObject: GameObjectState, fade: Fade, currentTime: number): Animation {
    const startOpacity = gameObject.opacity;
    const difference = fade.targetOpacity - startOpacity;
    const duration = Math.abs(difference) / fade.speed;

    const easing = setupEasing(fade.easing);

    const onTick = (progress: number) => {
        gameObject.opacity = startOpacity + difference * easing(progress);
    }

    const onFinal = () => {
        gameObject.opacity = fade.targetOpacity;
    }

    return {
        startTime: currentTime,
        endTime: currentTime + duration,
        onTick,
        onFinal
    };
}

export function setupMoveToPossessions(thing: GameObjectState, currentTime: number): Animation {

    // TODO: Implement a "possessions" list

    return {
        startTime: currentTime,
        endTime: currentTime + 100,
        onTick: () => { },
        onFinal: () => {
            thing.click = [];
            thing.clickable = false;
            thing.state = 'unpresent';
        }
    };
}

export function updateAnimations(animations: Animation[], elapsedTime: number) {
    let remaining = animations.length;
    prune: for (let i = 0; i < remaining; ++i) {
        // will change the relative order of execution of animations
        // but requires only a single swap per finalized animation
        while (animations[i].endTime < elapsedTime) {
            animations[i].onFinal();
            animations[i] = animations[--remaining];
            if (i === remaining) {
                break prune;
            }
        }
    }
    animations.length = remaining;

    for (const animation of animations) {
        if (animation.startTime <= elapsedTime) {
            animation.onTick((elapsedTime - animation.startTime) / (animation.endTime - animation.startTime))
        }
    }
}