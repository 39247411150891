import React from 'react'
import { AreaNode } from './area_nodes'
import styles from './../Settings.module.scss'

export type ChoiceNode = Omit<AreaNode, 'subareas'> & { isChosen: boolean, choiceSubNodes: ChoiceNode[] }

export const convertNodeToChoiceNode = (node: AreaNode, ...chosenIds: string[]): ChoiceNode => {
    return { ...node, isChosen: chosenIds.some(ci => ci === node.id), choiceSubNodes: node.subareas ? node.subareas.map(n => convertNodeToChoiceNode(n, ...chosenIds)) : [] }
}

const displayNode = (node: ChoiceNode, handleChosenNodes: (id: string) => void, index: number, parentIsSelected?: boolean) => {
    return <span key={node.id}>
        <input id={'node_' + node.id} type="checkbox" onChange={() => handleChosenNodes(node.id)} checked={parentIsSelected || node.isChosen} />
        <label htmlFor={'node_' + node.id}>{node.headline}</label>
        {node.choiceSubNodes && node.choiceSubNodes.length > 0 && <ul className={styles.nodeList}>{
            node.choiceSubNodes.map(cn => cn.headline.trim() !== '' && <li key={cn.id}>{displayNode(cn, handleChosenNodes, index, node.isChosen)}</li>)
        }</ul>}
    </span>
}

export const getChosenNodes = (nodes: ChoiceNode[]): ChoiceNode[] => {
    return nodes.flatMap(cn => {
        const tempNodes = getChosenNodes(cn.choiceSubNodes)
        if (cn.isChosen) tempNodes.push(cn)
        return tempNodes;
    })
}

export type NodeSettingsProps = {
    nodes: ChoiceNode[],
    setChosenNodes: (chosenNodes: ChoiceNode[]) => void
}

const NodeSettings = (props: NodeSettingsProps) => {
    const { nodes: chosenNodes, setChosenNodes } = props

    const displayChosenNodes = () => {
        const selectedNodes = getChosenNodes(chosenNodes)
        return selectedNodes.length > 0 ? selectedNodes.map(dn => dn.headline).join(',') : undefined
    }

    const setNodeAsChosen = (nodes: ChoiceNode[], id: string) => {
        nodes.map((tn) => {
            if (id === tn.id) {
                tn.isChosen = !tn.isChosen
            } else {
                tn.isChosen = false;
                // tn.choiceSubNodes = setNodesAsChosen(tn.choiceSubNodes, id);
            }
            tn.choiceSubNodes = setNodeAsChosen(tn.choiceSubNodes, id);
            return tn
        })
        return nodes
    }

    const handleChosenNodes = (id: string) => {
        let tempNodes = chosenNodes.slice();
        tempNodes = setNodeAsChosen(tempNodes, id)
        setChosenNodes(tempNodes)
    }


    return <div className={styles.settingsCard} data-testid='node_settings'>
        <h4 data-testid='node_settings_header'>Övningsområde</h4>
        {chosenNodes.length > 0 ? <>
            <div data-testid='node_settings_chosen'>
                Valt område: {displayChosenNodes() ?? 'Alla områden'}
            </div>
            <input id="allNodes" type="checkbox" onChange={() => (handleChosenNodes('-1'))} checked={displayChosenNodes() === undefined} />
            <label htmlFor="allNodes">Alla områden</label>
            <div className={styles.settingsList} data-testid='node_settings_choices_menu'>
                {chosenNodes.map((n, index) => n.headline.trim() !== '' && <div key={index}>
                    {displayNode(n, handleChosenNodes, index)}
                </div>)}
            </div>
        </> : <div data-testid='node_settings_not_found'>Inga områden hittade</div>}
    </div>
}

export default NodeSettings