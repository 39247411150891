import bezier from 'bezier-easing';

type BezierParameters = Parameters<typeof bezier>;

type CurveName =
    | 'linear'
    | 'ease'
    | 'ease-in'
    | 'ease-out'
    | 'ease-in-out'

const curves: Record<CurveName, BezierParameters> = {
    'linear': [0, 0, 1, 1],
    'ease': [0.25, 0.1, 0.25, 1],
    'ease-in': [0.42, 0, 1, 1],
    'ease-out': [0, 0, 0.58, 1],
    'ease-in-out': [0.42, 0, 0.58, 1],
}

export type EasingCurve = CurveName | BezierParameters;

export const setupEasing = (curve: EasingCurve): ReturnType<typeof bezier> => {
    const [x1, y1, x2, y2] = typeof curve === 'string' ? curves[curve] : curve;
    return bezier(x1, y1, x2, y2);
}
