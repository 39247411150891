import React from 'react';
//import styles from './Task.module.css'; 

function PickWords() {
  return (
    <>
      <h2>Vilka ord är stavade rätt?</h2>
      <div>...lista med ord som går att klicka på...</div>
      <div>....behöver ta fram modell för uppgiften, samt callback för matchning...</div>
    </>
  );
}

export default PickWords;