import Challenge from 'components/challenge'
import Scene from 'components/scene'
import { Score } from 'components/sideMenus/Stats'
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes'
import { AreaNodeMasteries, GameEngine, GameState } from 'engine/game'
import React, { useEffect, useState } from 'react'
import styles from './Modes.module.scss'
import { bronzeAward, gemBlueAward, gemGreenAward, gemRedAward, golbarAward, silverAward, stoneAward, woodAward } from 'components/settings/AreaNodeSettings/AreaNodeSettings'
import gemRedPng from './gemRed.png'
import gemGreenPng from './gemGreen.png'
import gemBluePng from './gemBlue.png'
import goldBarPng from './goldbar.png'
import silverBarPng from './silverbar.png'
import bronzeBarPng from './bronzebar.png'
import woodPng from './wood.png'
import stonePng from './stone.png'

const AdventureTime = (props: {
    engine: GameEngine,
    state: GameState,
    score: Score,
    setScore: (value: number) => void,
    nodes: AreaNode[],
    masteries: AreaNodeMasteries,
    showOverlay: boolean,
    setShowOverlay: (value: boolean) => void,
}) => {
    const {
        engine,
        state,
        score,
        setScore,
        nodes,
        masteries,
        showOverlay,
        setShowOverlay,
    } = props

    const logChallenge = () => {
        return true
    }


const getGemImage = (totalMastery: number) => {

    if (totalMastery === gemBlueAward) return <img className={styles.exitStageLeft} src={gemBluePng} alt='diamant' title="diamant" />
    else if (totalMastery === gemRedAward) return <img className={styles.exitStageLeft} src={gemRedPng} alt='rubin' title="rubin" />
    else if (totalMastery === gemGreenAward) return <img className={styles.exitStageLeft} src={gemGreenPng} alt='smaragd' title="smaragd" />
    else if (totalMastery === golbarAward) return <img className={styles.exitStageLeft} src={goldBarPng} alt='guld' title="guld" />
    else if (totalMastery === silverAward) return <img className={styles.exitStageLeft} src={silverBarPng} alt='silver' title="silver" />
    else if (totalMastery === bronzeAward) return <img className={styles.exitStageLeft} src={bronzeBarPng} alt='brons' title="brons" />
    else if (totalMastery === woodAward) return <img className={styles.exitStageLeft} src={woodPng} alt='wood' title="trä" />
    else if (totalMastery === stoneAward) return <img className={styles.exitStageLeft} src={stonePng} alt='stone' title="sten" />
    else return undefined;
}

    const [gem, setGem] = useState<JSX.Element>()

    const [currentMasteries, setCurrentMasteries] = useState<AreaNodeMasteries>({});
    const updateNodeMastery = (node: string, scoreUpdate: number) => {
        const newMasteries = { ...currentMasteries }
        const updatedScore = (newMasteries[node] ?? 0 )+ scoreUpdate
        newMasteries[node] = updatedScore
        setCurrentMasteries(newMasteries)
        console.log('updated score', updatedScore)
        const award = getGemImage(updatedScore);
        console.log('award', award)
        if(award) {
            setGem(award)
            setTimeout(() => {
                setGem(undefined)
            }, 2000)
        }
    }
    useEffect(() => {
        setCurrentMasteries(masteries)
    }, [masteries])

    return <>
        <Scene sceneModel={state.scene} onEvent={engine.pointerEvent} saveStateHandler={engine.saveStateHandler} score={score} nodes={nodes ?? []} masteries={masteries ?? []} switchGameMode={engine.actions.switchGameMode} showOverlay={showOverlay} setShowOverlay={setShowOverlay}></Scene>
        {logChallenge() && state.challenge && <Challenge
            {...state.challenge}
            score={score}
            setTotalScore={setScore}
            getTask={engine.getTask}
            onSuccess={engine.actions.completeChallenge}
            onRetreat={engine.actions.retreatFromChallenge}
            saveAnswer={engine.saveStateHandler?.saveAnswer}
            currentNodeMasteries={currentMasteries}
            setCurrentMasteries={updateNodeMastery}
        />}
        {gem && gem}
    </>
}

export default AdventureTime;