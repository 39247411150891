import FlashCard, { FlashProps } from 'components/flashCard';
import { GameEngine, GameState } from 'engine/game';
import React, { useState } from 'react';


const FlashCardMode = (props: {
    engine: GameEngine,
    state: GameState,
}) => {
    const {
        engine,
        state,
    } = props

    const [isFlashCard, setIsFlashCard] = useState<boolean>(false);
    const [nrOfQuestions, setNrOfQuestions] = useState<number>(5);
    const flashProps: FlashProps = {
        nrOfQuestions: nrOfQuestions,
        getTask: () => { console.log('getTask'); return engine.getTask() },
        onRetreat: () => { console.log('onRetreat'); setIsFlashCard(false) },
        onSuccess: (options?: { value: number, type: string }) => { console.log('done'); setIsFlashCard(false) }
    }

    const click = (nrOfQuestions: number) => {
        setNrOfQuestions(nrOfQuestions);
        engine.getTask();
        setIsFlashCard(true);
    }
    return (
        <div>
            <h1>Flash Card Mode</h1>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
                <button onClick={() => click(5)}>Start 5 Questions</button>
                <button onClick={() => click(15)}>Start 15 Questions</button>
                <button onClick={() => click(25)}>Start 25 Questions</button>
            </div>
            {isFlashCard && <FlashCard {...flashProps}></FlashCard>}
        </div>
    );
}

export default FlashCardMode;