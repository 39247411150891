import Challenge from 'components/challenge'
import { Score } from 'components/sideMenus/Stats'
import { GameEngine, GameState, OneHundredGameMode } from 'engine/game'
import React, { useContext, useEffect, useState } from 'react'
import goldenArrowAnimated from './golden_arrow animated.png'
import gameService from 'services/gameService'
import { useStudent } from 'providers/SignInProvider'
import { TaskDefinition } from '@task'
import { PuzzleDefinition } from 'engine/models/challenge'
import oneHundred from './oneHundred.svg'
import { GameGlobalsContext } from 'contexts/GameGlobalsContext'
import { ChallengeFeedback, ChallengeFeedbackProps } from 'components/challenge/ChallengeFeedback'
import challengeStyles from "./../challenge/Challenge.module.scss"

const basicChallange: PuzzleDefinition = {
    type: 'puzzle',
    requiredScore: -1, // non-positive numbers are treated like infinity
    playerAvatar: null,
    otherAvatar: oneHundred,
    introText: 'hur många rätt får du på 100 frågor?',
    retreatText: 'Avsluta',
    challengeText: 'Börja',
    successText: '', // requiredScore is non-positive, so this will never be used
}

const OneHundredMode = (props: {
    state: GameState,
    engine: GameEngine,
    score: Score,
    setScore: (value: number) => void,
    mode: OneHundredGameMode
}) => {
    const {
        state,
        engine,
        score,
        setScore,
        mode,
    } = props

    const [hasStarted, setHasStarted] = useState(false)
    const [hasEnded, setHasEnded] = useState(false)
    const [hundredScore, setHundredScore] = useState(0)
    const [nrOfQuestionsAnswered, setNrOfQuestionsAnswered] = useState(0)
    const student = useStudent()
    // const questionAmount = 100
    const [selectedAmount, setSelectedAmount] = useState<number>(50)
    const questionAmounts = [25, 50, 100]

    const completeChallenge = () => {
        setHasEnded(true)
        setHasStarted(false)
        engine.actions.retreatFromChallenge()
    }

    const handleScore = (value: number) => {
        setScore(value)
        setHundredScore(hs => hs + value)
        setNrOfQuestionsAnswered(n => ++n)
        console.log('nr of questions', nrOfQuestionsAnswered)
    }

    const back = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'hundredModeFinished', hundredModeProps: { 
            score: hundredScore, 
            procent: hundredScore / selectedAmount * 100,
            nrOfQuestions: selectedAmount,
        } 
        });
        engine.actions.retreatFromChallenge()
        engine.actions.switchGameMode(undefined)
        setHasEnded(true);
    }

    const startChallenge = () => {
        setHasStarted(true)
        engine.actions.startChallenge('id', { ...basicChallange, introText: `hur många rätt får du?` })
        setHasEnded(false)
    }

    useEffect(() => {
        if (!hasEnded) {
            if (hasStarted && selectedAmount && nrOfQuestionsAnswered >= selectedAmount) {
                setHasStarted(false)
                setHasEnded(true)
                console.log('score', hundredScore)
                gameService.saveOneHundredModeScore(hundredScore, selectedAmount, student?.id?.toString() ?? '')
            }
        }

    }, [engine, hasEnded, hasStarted, hundredScore, nrOfQuestionsAnswered, student, selectedAmount])

    return <>
        {!hasStarted && !hasEnded && <>
            <div className={challengeStyles.challenge} style={{ textAlign: 'center' }}>
                <img src={oneHundred} style={{ width: '80px', maxWidth: '50%', marginBottom: '20px' }} alt="Trophy" />
                <div>Hur många rätt får du?</div>
                <br />
                <div style={{marginBottom: '5px'}}>Välj antal frågor:</div>
                <select value={selectedAmount} onChange={(event) => setSelectedAmount(+event.target.value)}>
                    {questionAmounts.map((amount, index) => <option key={index} value={amount}>{amount}</option>)}
                </select>
                <br />
                <img src={goldenArrowAnimated} style={{ width: '50px', maxWidth: '10%', marginTop: '20px' }} alt="Golden arrow" onClick={startChallenge} />
            </div>
        </>}
        {hasStarted && !hasEnded && (state.challenge && <Challenge
            {...state.challenge}
            questionAmount={Math.floor(nrOfQuestionsAnswered / selectedAmount * 100) / 100}
            score={score}
            setTotalScore={handleScore}
            getTask={engine.getTask}
            onSuccess={completeChallenge}
            onRetreat={completeChallenge}
            saveAnswer={engine.saveStateHandler?.saveAnswer}
        />)}
        {hasEnded && <div style={{ position: 'absolute', top: '0', bottom: '0', left: '0', right: '0', backgroundColor: '#f6d635', textAlign: 'center', }} >
            <img style={{ maxWidth: '10%', marginTop: '50px' }} src={oneHundred} alt='100' />
            <h4>Du fick {hundredScore / selectedAmount * 100}% rätt på {selectedAmount} frågor</h4>
            <img src={goldenArrowAnimated} style={{ maxWidth: '10%' }} alt="Golden arrow" onClick={back} />
        </div>}
    </>
}

export default OneHundredMode