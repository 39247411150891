import React from "react";
import style from './ErrorPage.module.css'
import golderArror from './golden_arrow animated.png'
import errorMonster from './server not found.png'
function ErrorPage() {

    return <div className={style.error}>
        <h4>Något gick visst fel!</h4>
        <img src={errorMonster} alt="errorMonster"  className={style.errorMonster} />
        <div>Prova igen så fungerar det nog bättre</div>
        <br />
        <a href="/"><img src={golderArror} alt="guldpil - tillbaka till hemsida" style={{ maxWidth: '50px' }} /></a>
    </div>
}

export default ErrorPage;