import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type AsyncValueReloadOptions = {
    staleWhileRevalidate?: boolean;
}

export type AsyncValueState<T> =
    | { type: 'loading' }
    | { type: 'value', value: T }
    | { type: 'error', error: unknown }

export type AsyncValue<T> = {
    state: AsyncValueState<T>,
    setState: (value: T) => void;
    refreshState: (options?: AsyncValueReloadOptions) => void;
}

/** A message to display as a warning to users of {@link useAsyncValue} when failing to respond to an abort request. */
const useAsyncValueAbortIgnoredWarningMessage =
    'useAsyncValue received a value after signalling to abort. Please use the provided AbortSignal to abort pending requests.'

/** The static definition of the loading state of {@link useAsyncValue}.
 *
 * Because it is static, React equality checks, which check for reference-equality, work as expected. */
const asyncValueLoadingState: AsyncValueState<unknown> = { type: 'loading' };

/** A React hook for asynchronously loading data.
 *
 * The hook takes a callback which is responsible for loading data.
 * The callback is given an AbortSignal which is used by the hook to signal
 * when it no longer needs a value; this could happen if the hook is unmounted
 * or if it receives a command like {@link AsyncValue.refreshState}.
 */
const useAsyncValue = <T extends unknown>(getValue: (signal: AbortSignal) => PromiseLike<T>): AsyncValue<T> => {
    const [state, _setState] = useState<AsyncValueState<T>>(asyncValueLoadingState);
    const ctrlRef = useRef(new AbortController());

    const setState: AsyncValue<T>['setState'] = useCallback((value: T) => {
        ctrlRef.current.abort();

        _setState({ type: 'value', value });
    }, []);

    const refreshState: AsyncValue<T>['refreshState'] = useCallback((options: AsyncValueReloadOptions = {}) => {
        ctrlRef.current.abort();

        if (!options.staleWhileRevalidate && state !== asyncValueLoadingState) {
            _setState(asyncValueLoadingState);
        }

        const ctrl = ctrlRef.current = new AbortController();

        getValue(ctrl.signal).then(
            (value) => {
                if (ctrl.signal.aborted) {
                    return console.warn(useAsyncValueAbortIgnoredWarningMessage);
                }
                _setState({ type: 'value', value });
            },
            (error) => {
                if (ctrl.signal.aborted) {
                    return console.warn(useAsyncValueAbortIgnoredWarningMessage);
                }
                _setState({ type: 'error', error });
            },
        );
    }, [getValue]);

    // this effect should be set up and destroyed only once
    useEffect(() => {
        // load the state once on component mount
        refreshState();

        // abort any pending requests on component unmount
        return () => ctrlRef.current.abort()
    }, []);

    return useMemo(() => ({ state, setState, refreshState }), [state, setState, refreshState]);
}

export default useAsyncValue;
