import React, { CSSProperties } from 'react';
import { MessageModel } from 'engine/models/gameObject';
import styles from './Scene.module.scss';

function getElementPosition(props: MessageModel): CSSProperties {
    switch (props.direction) {
        case "center":
            return {
                transform: `translateX(-50%) translateY(50%)`,
                bottom: `${50-props.anchorY}%`,
                left: `${50-props.anchorX}%`
            };
        case "left":
            return {
                bottom: `${50+props.anchorY}%`,
                right: `${100-props.anchorX}%`
            };
        case "right":
            return {
                bottom: `${50+props.anchorY}%`,
                left: `${100-props.anchorX}%`
            };
    }
}

function SpeechBubble(props: MessageModel) {
    const elementStyle: CSSProperties = {
        ...getElementPosition(props), 
        width: `${props.width}%`
    }; 
    return (
        <div className={`${styles.speechBubble} ${styles[props.direction]}`} style={elementStyle}>
            <span>{props.text}</span>
        </div>
    );
}

export default SpeechBubble;