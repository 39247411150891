import { DependencyList, useEffect, useRef } from "react";

const useAnimation = (callback: FrameRequestCallback, deps: DependencyList | undefined) => {
    const frameRef = useRef<number>();

    const animate: FrameRequestCallback = time => {
        callback(time);
        frameRef.current = requestAnimationFrame(animate);
    }

    useEffect(() => {
        frameRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(frameRef.current as number);
    }, deps); 
}

export default useAnimation; 