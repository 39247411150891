import React, { useEffect, useState } from 'react';
import style from './DifficultyModal.module.scss';
import profileService from 'services/profileService';
import beltWhitePng from './belt_white.png';
import beltBluePng from './belt_blue.png';
import beltBlackPng from './belt_black.png';
import { useStudent } from 'providers/SignInProvider';

const DifficultyModal = () => {
    const [hasDifficulty, setHasDifficulty] = useState<boolean>()
    const student = useStudent();

    useEffect(() => {
        if (!student || !student.id) return;
        if (!student.playerSettings?.difficulty) {
            setHasDifficulty(false)
        }
    }, [student])

    const handleClick = (difficulty: string) => {
        let difficultyMin = -1;
        let difficultyMax = -1;
        if (difficulty === 'easy') {
            difficultyMin = 0;
            difficultyMax = 500;
        } else if (difficulty === 'medium') {
            difficultyMin = 400;
            difficultyMax = 700;
        } else if (difficulty === 'hard') {
            difficultyMin = 600;
            difficultyMax = 1000;
        }
        profileService.setUserDifficulty({ min: difficultyMin.toString(), max: difficultyMax.toString() }).then(result => {
            setHasDifficulty(true);
            window.location.reload();
        });
    }

    return <>{
        hasDifficulty === false &&
        <div id='difficulty-module' className={style.difficultyModal}>
            <h3 className={style.difficultyHeader}>Välj din nivå</h3>
            <div id="white-belt" className={style.difficultyImgBox} onClick={() => handleClick('easy')}><img className={style.difficultyImg} src={beltWhitePng} alt='easy' />
                {/* <span>Easy</span> */}
            </div>
            <div id="blue-belt" className={style.difficultyImgBox} onClick={() => handleClick('medium')}><img className={style.difficultyImg} src={beltBluePng} alt='medium' />
                {/* <span>Medium</span> */}
            </div>
            <div id="black-belt" className={style.difficultyImgBox} onClick={() => handleClick('hard')}><img className={style.difficultyImg} src={beltBlackPng} alt='hard' />
                {/* <span>Hard</span> */}
            </div>
        </div>}</>
}

export default DifficultyModal;