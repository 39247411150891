import React, { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import iconStarts from './stars3.svg';
import iconWrongAnswer from './wrong_answer.svg';
import styles from './ChallengeFeedback.module.scss';
import goldenArrowAnimated from './golden_arrow animated.png'
// import ReportBug from './ReportBug';
import reportProblemSvg from './report_a_problem.svg'
import iconCorrectSvg from './icon_correct.svg'
import trophy from './trophy.svg'
import ContinuePlaying from './ContinuePlaying';

export type ChallengeFeedbackProps = {
    type: '' | 'correct' | 'incorrect';
    taskId: string;
    taskType: string;
    taskNode: string,
    taskDifficulty: string,
    comment: ReactNode;
    question: ReactNode;
    correctAnswer: ReactNode;
    givenAnswer: ReactNode;
    taskComment: ReactNode;
    rule: ReactNode;
    example: ReactNode;
    score: number;
    streakScore?: ReactNode;
    onClick: (event: React.MouseEvent<Element, MouseEvent>, useLife?: boolean) => void;
    className?: string;
    bugReportSent: boolean;
    bugReportSending: boolean;
    setOpenBugReport: (value: boolean) => void;
    bonusPoints?: number;
    extraLives: number;
};

const initialFeedbackState: ChallengeFeedbackProps = {
    type: '',
    taskId: '',
    taskType: '',
    taskNode: '',
    taskDifficulty: '',
    comment: '',
    question: '',
    correctAnswer: '',
    givenAnswer: '',
    taskComment: '',
    rule: '',
    example: '',
    score: 0,
    onClick: () => { },
    bugReportSent: false,
    bugReportSending: false,
    setOpenBugReport: () => { },
    bonusPoints: 0,
    extraLives: 0,
};

export const useFeedbackState = (): [ChallengeFeedbackProps, (newState: ChallengeFeedbackProps) => void] => {
    const [feedbackState, setFeedbackState] = useState(initialFeedbackState);
    // const [feedbackTimeoutTrigger, setFeedbackTimeoutTrigger] = useState<number>(0);
    const feedbackTimeout = useRef<ReturnType<typeof setTimeout>>();

    const resetFeedbackState = (event: React.MouseEvent<Element, MouseEvent>, onClick: MouseEventHandler): void => {
        setFeedbackState(initialFeedbackState);
        if (feedbackTimeout.current) {
            feedbackTimeout.current = void clearTimeout(feedbackTimeout.current);
        }
        onClick(event)
    };

    // useEffect(() => {
    //     const timeout = feedbackTimeout.current = setTimeout(resetFeedbackState, 2500);
    //     return () => clearTimeout(timeout);
    // }, [feedbackTimeoutTrigger]);

    return [
        feedbackState,
        (newFeedbackState) => {
            // setFeedbackTimeoutTrigger(~feedbackTimeoutTrigger);
            setFeedbackState({ ...newFeedbackState, onClick: (event, useLife) => resetFeedbackState(event, () => newFeedbackState.onClick(event, useLife)) });
        },
    ];
};

type ChallengeFeedbackTypeProps = {
    active: boolean;
    banner: ReactNode;
    iconImgSrc: string | null;
}

export const challengeFeedbackTypeProps: Record<ChallengeFeedbackProps['type'], ChallengeFeedbackTypeProps> = {
    '': {
        active: false,
        banner: null,
        iconImgSrc: null,
    },
    correct: {
        active: true,
        banner: 'Rätt svar!',
        iconImgSrc: iconStarts,
    },
    incorrect: {
        active: true,
        banner: 'Fel svar!',
        iconImgSrc: iconWrongAnswer,
    },
};

const createFireworks = () => {
    const fireworks = [];
    const getRotation = (i: number) => {
        switch (i % 4) {
            case 0:
                return '0deg';
            case 1:
                return '90deg';
            case 2:
                return '180deg';
            case 3:
                return '270deg';

            default:
                return '0deg';
        }
    };
    for (let i = 0; i < 6; i++) {
        fireworks.push(<img key={i} src={'/assets/firework-7790_256.gif'} className={styles.fireworks} style={{ gridColumn: i % 2 + 1, gridRow: i % 3 + 1, rotate: getRotation(i) }} alt="firework" />);
    }
    return fireworks;
}

const CreatePlus = (limit: number, goAround: number, setGoAround: (value: number) => void, setTaskBonusPoints: (value: number) => void, setPlus: (value: JSX.Element) => void) => {
    const tempGoAround = goAround + 1;
    if (limit >= tempGoAround) {
        const element = <img
            key={goAround}
            className={`${styles.plusAscend}`}
            onAnimationEnd={() => { setTaskBonusPoints(tempGoAround); setGoAround(tempGoAround); }}
            style={{ width: '40px', zIndex: 13 }}
            src={'/assets/plus.png'}
            alt="plus" />;
        setPlus(element);
    } else {
        setTaskBonusPoints(goAround);
        setPlus(<></>);
    }
}

const HandlePluses = (bonusPoints: number, taskBonusPoints: number, setTaskBonusPoints: (value: number) => void, goAround: number, setGoAround: (value: number) => void) => {
    const [plus, setPlus] = useState<JSX.Element>();

    useEffect(() => {
        CreatePlus(bonusPoints, goAround, setGoAround, setTaskBonusPoints, setPlus);
    }, [goAround])

    return <div className={styles.plusContainer}>{plus}</div>
}

const VerticleMobile = (props: ChallengeFeedbackProps
    & {
        taskBonusPoints: number,
        setTaskBonusPoints: (value: number) => void,
        goAround: number,
        setGoAround: (value: number) => void,
        useLife?: boolean,
    }) => {
    const typeProps = challengeFeedbackTypeProps[props.type];
    return <>
        <div id={`feedback ${props.type === 'correct' ? 'feedback-correct' : 'feedback-incorrect'}`} onClick={(event) => props.onClick(event, props.useLife)} className={`${styles.feedback} ${typeProps.active ? styles.active : ''} ${props.className}`}>
            {props.bonusPoints && (props.bonusPoints > props.goAround) && <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', position: 'absolute', inset: 0 }}>
                {createFireworks()}
            </div>}
            {/* the height of the image defines the height of the feedback box */}
            {/* <img src={feedbackCard} width="100%" alt='Feedback card for desktop'/> */}
            <div className={`${styles.feedbackBanner} ${props.type ? (props.type === 'correct' ? `${styles.feedbackBannerCorrect}` : `${styles.feedbackBannerIncorrect}`) : ''}`}>
                <div className={styles.feedbackBannerText}>{typeProps.banner}</div>
            </div>
            <div className={`${styles.feedbackBody} ${props.type ? props.type === 'correct' ? styles.correct : styles.incorrect : ''}`}>
                <br />
                <br />
                {/* {props.comment} */}
                {typeProps.iconImgSrc && <div style={{ marginTop: '1.0em' }}>
                    <img src={typeProps.iconImgSrc} style={{ maxWidth: '40%', width: '6em' }} alt='Big checkmark' />
                </div>}
                <br />
                {props.question}
                <br />
                <br />
                {props.correctAnswer}
                <br />
                {props.taskComment}
                <br />
                {props.type === 'incorrect' && props.givenAnswer}
                <br />
                <img src={goldenArrowAnimated} style={{ maxWidth: '10%' }} alt='Next arrow' />
                <br />
                <br />
                <br />
                {props.bonusPoints && HandlePluses(props.bonusPoints ?? 0, props.taskBonusPoints, props.setTaskBonusPoints, props.goAround, props.setGoAround)}
                {props.type === 'correct' ? <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                    <div style={{ background: 'yellow', padding: '5px', width: '50%' }}>
                        <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={trophy} alt='trophy' />Du har nu {props.score + props.taskBonusPoints} poäng!
                    </div>
                </div> : <br />}
                <br />
                {props.streakScore ? <span>{props.streakScore}</span> : <br />}
                <br />
                <br />
                {props.rule}<br /><br />
                {props.example}
                <br />
                <div className={styles.reportProblemButtonBox} style={{ cursor: props.bugReportSending || props.bugReportSent ? 'default' : 'pointer' }} onClick={() => props.bugReportSending || props.bugReportSent ? {} : props.setOpenBugReport(true)} >
                    {!props.bugReportSent ? <><img id='report_problem' style={{ maxWidth: '20px', cursor: 'pointer', opacity: !props.bugReportSending ? '100%' : '50%' }} src={reportProblemSvg} alt='report a problem' />
                        <div>Anmäl fråga</div>
                    </> : <><img id='bug_reported' style={{ maxWidth: '20px' }} src={iconCorrectSvg} alt='icon correct' />
                        <div>Frågan är anmäld</div>
                    </>}
                </div>
                <br />
            </div>
        </div>
    </>
}

const HorizontalMobile = (props: ChallengeFeedbackProps
    & {
        taskBonusPoints: number,
        setTaskBonusPoints: (value: number) => void,
        goAround: number,
        setGoAround: (value: number) => void,
        useLife?: boolean,
    }) => {
    const typeProps = challengeFeedbackTypeProps[props.type];
    return <>
        <div id='feedback_window' onClick={(event) => props.onClick(event, props.useLife)} className={`${styles.feedback} ${typeProps.active ? styles.active : ''} ${props.className}`}>
            {props.bonusPoints && (props.bonusPoints > props.goAround) && <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', position: 'absolute', inset: 0 }}>
                {createFireworks()}
            </div>}
            {/* the height of the image defines the height of the feedback box */}

            {/* <img src={feedbackCard} width="100%" alt='Feedback card for desktop'/> */}
            <div id={props.type === 'correct' ? 'feedback-correct' : 'feedback-incorrect'} className={`${styles.feedbackBanner} ${props.type ? (props.type === 'correct' ? `${styles.feedbackBannerCorrect}` : `${styles.feedbackBannerIncorrect}`) : ''}`}>
                <div className={styles.feedbackBannerText}>{typeProps.banner}</div>
            </div>
            <div className={`${styles.feedbackBody} ${props.type ? props.type === 'correct' ? styles.correct : styles.incorrect : ''}`}>
                <div style={{ gridArea: 'left', width: '100%' }}>
                    <br />
                    <br />
                    {/* {props.comment} */}
                    {typeProps.iconImgSrc && <span style={{ marginTop: '1.0em' }}>
                        <img src={typeProps.iconImgSrc} style={{ maxWidth: '40%', width: '4em' }} alt='Big checkmark' />
                    </span>}
                    <br />
                    <span>{props.question}</span>
                    <br />
                    <span>{props.correctAnswer}</span>
                    <br />
                    {props.taskComment}
                    <br />
                    {props.type === 'incorrect' && props.givenAnswer}
                    <br />
                </div>
                <div style={{ gridArea: 'right', width: '100%' }}>
                    <br />
                    <br />
                    {props.bonusPoints && HandlePluses(props.bonusPoints ?? 0, props.taskBonusPoints, props.setTaskBonusPoints, props.goAround, props.setGoAround)}
                    {props.type === 'correct' ? <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                        <div style={{ background: 'yellow', padding: '5px', width: '50%' }}>
                            <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={trophy} alt='trophy' />Du har nu {props.score + props.taskBonusPoints} poäng!
                        </div>
                    </div> : <br />}
                    <br />
                    {props.streakScore ? <span>{props.streakScore}</span> : <br />}
                    <br />
                    {props.rule}
                    <br />
                    <br /><br />
                    {props.example}
                    <br />
                    <br />
                </div>
                <div style={{ gridArea: 'bottom' }} >
                    <img src={goldenArrowAnimated} style={{ maxWidth: '5%' }} alt='Next arrow' />
                    <br />
                    <div className={styles.reportProblemButtonBox} style={{ cursor: props.bugReportSending || props.bugReportSent ? 'default' : 'pointer' }} onClick={() => props.bugReportSending || props.bugReportSent ? {} : props.setOpenBugReport(true)} >
                        {!props.bugReportSent ? <><img id='report_problem' style={{ maxWidth: '20px', cursor: 'pointer', opacity: !props.bugReportSending ? '100%' : '50%' }} src={reportProblemSvg} alt='report a problem' />
                            <div>Anmäl fråga</div>
                        </> : <><img id='bug_reported' style={{ maxWidth: '20px' }} src={iconCorrectSvg} alt='icon correct' />
                            <div>Frågan är anmäld</div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </>
}

const DesktopScreen = (props: ChallengeFeedbackProps
    & {
        taskBonusPoints: number,
        setTaskBonusPoints: (value: number) => void,
        goAround: number,
        setGoAround: (value: number) => void,
        useLife?: boolean,
    }) => {
    const typeProps = challengeFeedbackTypeProps[props.type];
    return <>
        <div id={`feedback feedback-${props.type}`} onClick={(event) => props.onClick(event, props.useLife)} className={`${styles.feedback} ${typeProps.active ? styles.active : ''} ${props.className}`}>
            {props.bonusPoints && (props.bonusPoints > props.goAround) && <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', position: 'absolute', inset: 0 }}>
                {createFireworks()}
            </div>}
            {/* the height of the image defines the height of the feedback box */}
            {/* <img src={feedbackCard} width="100%" alt='Feedback card for desktop'/> */}
            <div style={{ height: '100%' }}>
                <div className={`${styles.feedbackBanner} ${props.type ? (props.type === 'correct' ? `${styles.feedbackBannerCorrect}` : `${styles.feedbackBannerIncorrect}`) : ''}`}>
                    <div className={styles.feedbackBannerText}>{typeProps.banner}</div>
                </div>
                <div className={`${styles.feedbackBody} ${props.type ? props.type === 'correct' ? styles.correct : styles.incorrect : ''}`}>
                    <div style={{ gridArea: 'left', width: '100%' }}>
                        <br />
                        <br />
                        <br />
                        {/* {props.comment} */}
                        {typeProps.iconImgSrc && <div style={{ marginTop: '1.0em' }}>
                            <img src={typeProps.iconImgSrc} style={{ maxWidth: '40%', width: '7em' }} alt='Big checkmark' />
                        </div>}
                        <br />
                        {props.question}
                        <br />
                        {props.correctAnswer}
                        <br />
                        {props.taskComment}
                        <br />
                        {props.type === 'incorrect' && props.givenAnswer}
                    </div>
                    <div style={{ gridArea: 'right', width: '100%' }}>
                        <br />
                        <br />
                        {props.bonusPoints && HandlePluses(props.bonusPoints ?? 0, props.taskBonusPoints, props.setTaskBonusPoints, props.goAround, props.setGoAround)}
                        {props.type === 'correct' ? <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            <div style={{ background: 'yellow', padding: '5px', width: '50%' }}>
                                <img style={{ maxWidth: '30px', maxHeight: '30px' }} src={trophy} alt='trophy' />Du har nu {props.score + props.taskBonusPoints} poäng!
                            </div>
                        </div> : <br />}
                        <br />
                        {props.streakScore ? <span>{props.streakScore}</span> : <br />}
                        <br />
                        <br />
                        <br />
                        {props.rule}<br /><br />
                        {props.example}
                        <br />
                        <br />
                    </div>
                    <div style={{ gridArea: 'bottom' }} >
                        <img src={goldenArrowAnimated} style={{ maxWidth: '5%' }} alt='Next arrow' />
                        <br />
                        <div className={styles.reportProblemButtonBox} style={{ cursor: props.bugReportSending || props.bugReportSent ? 'default' : 'pointer' }} onClick={() => props.bugReportSending || props.bugReportSent ? {} : props.setOpenBugReport(true)} >
                            {!props.bugReportSent ? <><img id='report_problem' style={{ maxWidth: '20px', cursor: 'pointer', opacity: !props.bugReportSending ? '100%' : '50%' }} src={reportProblemSvg} alt='report a problem' />
                                <div>Anmäl fråga</div>
                            </> : <><img id='bug_reported' style={{ maxWidth: '20px' }} src={iconCorrectSvg} alt='icon correct' />
                                <div>Frågan är anmäld</div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export const ChallengeFeedback = (props: ChallengeFeedbackProps): JSX.Element => {
    const [taskBonusPoints, setTaskBonusPoints] = useState<number>(0);
    const [goAround, setGoAround] = useState<number>(0);
    const [useLife, setUseLives] = useState<boolean>();

    useEffect(() => {
        setGoAround(0)
    }, [props.bonusPoints])

    useEffect(() => {
        if (props.taskId !== '') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'answer',
                answerProps: {
                    result: props.type,
                    task: {
                        taskType: props.taskType,
                        taskNode: props.taskNode,
                        taskDifficulty: props.taskDifficulty,
                    }
                }
            });
            setUseLives(undefined);
        }
    }, [props.taskId, props.score])
    return (
        <>
            <DesktopScreen className={styles.desktop} {...{ ...props, taskBonusPoints, setTaskBonusPoints, goAround, setGoAround, useLife }} />
            <VerticleMobile className={styles.verticleMobile} {...{ ...props, taskBonusPoints, setTaskBonusPoints, goAround, setGoAround, useLife }} />
            <HorizontalMobile className={styles.horizontalMobile} {...{ ...props, taskBonusPoints, setTaskBonusPoints, goAround, setGoAround, useLife }} />
            {useLife === undefined && <ContinuePlaying {...{ ...props, setUseExtraLives: setUseLives }} />}

        </>
    );
};
