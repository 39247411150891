import React from 'react';
//import styles from './Task.module.css'; 

function SortWords() {
  return (
    <>
      <h2>Sortera orden i (bokstavs)ordning</h2>
      <div>...text med luckor...</div>
      <div>...lista med ord i icke-ordning som går att dra-och-släppa, eller klicka på så de hamnar överst...</div>
      <div>....behöver ta fram modell för uppgiften, samt callback för rättning...</div>
    </>
  );
}

export default SortWords;