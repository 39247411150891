export type StudentSessionContext = {
    id?: number;
    userId: string;
    studentGroups: never[]; // TODO: implement
    playerSettings: null | {
        /** The in-game nickname of the player. */
        nickname: string;
        /** The difficulty the player has selected. */
        difficulty?: {
            /** The minimum difficulty the player is willing to play at. */
            min: string;
            /** The maximum difficulty the player is willing to play at. */
            max: string;
        }
    }
    /** If the student is not connected to a school class, they can supply that information themselves.
      * To mark this student-supplied information as unreliable, it is namespaced under this property. */
    schoolMarketing: null | {
        hasSchool: boolean;
        school: string;
        class: string;
    }
    extraLives: number;
    updateStudentContext: () => void;
}

async function ping() {
    fetch(`${process.env.REACT_APP_API_BASE}/session/init/ping`)
    await fetch(`${process.env.REACT_APP_API_BASE}/data/ping`,
        {
            credentials: 'include'
        })
}

async function logout(): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/session`, {
        method: 'DELETE',
        credentials: 'include',
    });
    if (!response.ok) {
        throw new Error('Unknown error when attempting to log out');
    }
    return;
}

async function getStudentSession(
    params?: {
        abortSignal?: AbortSignal
    }
): Promise<StudentSessionContext | null> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/session/context/student`, {
        method: 'GET',
        credentials: 'include',
        signal: params?.abortSignal,
    });
    if (response.status === 404) {
        throw new Error('Cannot get student session: user is not logged in')
    }
    if (!response.ok) {
        throw new Error('Unknown error when fetching student session context');
    }
    return response.json();
}

export const sessionService = {
    ping,
    getStudentSession,
    logout,
};

export default sessionService;
