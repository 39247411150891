import React from 'react';
import { MultipleChoiceTaskDefinition, TaskImplProps } from '.';
import { WriteSentence } from './TaskUtils';

export const getCorrectAnswer = (data: MultipleChoiceTaskDefinition) => data.alternatives.filter(a => a.isCorrect).map(a => a.answer).join(', ')

function MultipleChoice({ data, onCompletion, showInstructions }: TaskImplProps) {
    if (data.type !== 'MultipleChoice') {
        throw new Error(`Unexpected type ${data.type}`)
    }

    const { question, alternatives } = data;

    return (
        <div>
            {showInstructions && <h2 className='header' style={{fontFamily: 'Architects Daughter, cursive'}}>Välj rätt alternativ</h2>}
            <span>{<WriteSentence sentenceFragment={question} />}</span>
            {alternatives.filter(a => !(a.answer.trim() === '' && !a.isCorrect)).map(({ answer, isCorrect }, index) => (
                <div key={index} className=''>
                    <input className='' style={{ marginTop: '20px', marginBottom: '7px', padding: '7px 10px', wordWrap: 'normal', wordBreak: 'break-word', maxWidth: '100%', whiteSpace: 'normal', fontSize: answer.includes('^^') ? '300%' : '' }} key={index} type="button" value={answer.replaceAll('^^', '')}
                        onClick={() => onCompletion(data, isCorrect ? 1 : 0, getCorrectAnswer(data), data.nodeRules ?? '', answer)} />
                </div>
            ))}
        </div>)
}

export default MultipleChoice;
