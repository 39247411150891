import { DbTask, TaskDefinition } from "@task"
import { useState } from "react"
import gameService from "services/gameService"
import ChallengeDefinition from "./models/challenge"
import { useStudent } from "providers/SignInProvider"

export interface SceneState {
    adventureId: string
    sceneId: string
    challengeData?: ChallengeSaveState
    theme: string
}

export interface ChallengeSaveState {
    challengerId: string
}

export interface SaveStateHandler {
    saveScene: (sceneState: SceneState, replaceData: boolean) => Promise<void>
    loadScene: () => Promise<SceneState | null>
    saveChallenge: (challengeState: ChallengeSaveState) => void
    loadChallenge: () => { challengeState: ChallengeSaveState } | null
    saveAnswer: (task: TaskDefinition, isCorrect: boolean, selectedAnswer?: string, studentId?: string, bonusPoints?: number) => void
    uploadAnswers: () => void
    taskActivities: TaskActivity[]
}

export type TaskActivity = {
    id: number
    studentRoleId: string
    taskSubjectMatterLevelId: string
    metadata: MetaData
    createdDate: Date
    selectedAnswer?: string
}

export type MetaData = {
    /***
     * taskId:versionid
     */
    taskId: string
    task: TaskDefinition
    areaNode: string
    assessment: string
    secondsSpent: number
}

export const initSaveStateHandler: () => SaveStateHandler = () => {

    // const [taskActivities, setTaskActivities] = useState<TaskActivity[]>([])
    let taskActivities: TaskActivity[] = []
    var pageVisibility = document.visibilityState;
    document.addEventListener('visibilitychange', () => {
        // fires when user switches tabs, apps, goes to homescreen, etc.
        if (document.visibilityState === 'hidden') {
            sessionStorage.setItem('answers', JSON.stringify(taskActivities))
            // console.log('hidden')
        }

        // fires when app transitions from prerender, user returns to the app / tab.
        if (document.visibilityState === 'visible') {
            // const answers = sessionStorage.getItem('answers')
            // if (answers && taskActivities.length === 0) {
            //     taskActivities = JSON.parse(answers)
            //     console.log('answers', answers)
            // }
            // console.log('task Activities', taskActivities)
            // console.log('visible')
        }
    })

    // TODO: implement database persistence
    // TODO: make save and load functions synchronous and implement background sync
    const saveScene: SaveStateHandler['saveScene'] = async (sceneState, replaceData) => {
        // await Promise.resolve()
        // sessionStorage.setItem('sceneState', JSON.stringify(sceneState))
        await gameService.saveStateScene(sceneState, replaceData)

    }
    const loadScene: SaveStateHandler['loadScene'] = async () => {
        // await Promise.resolve()
        // return JSON.parse(sessionStorage.getItem('sceneState') || 'null')
        return (await gameService.getStateScene())?.data
    }
    const saveChallenge: SaveStateHandler['saveChallenge'] = async (challengeState) => {
        await gameService.saveChallengeState(challengeState)
        sessionStorage.setItem('challenge', JSON.stringify(challengeState))
    }
    const loadChallenge = () => {
        const challenge = sessionStorage.getItem('challenge')
        return challenge ? JSON.parse(challenge) : null
    }

    const saveAnswer = (task: TaskDefinition, isCorrect: boolean, selectedAnswer?: string, studentId?: string, bonusPoints?: number) => {
        const taskActivity: TaskActivity = {
            id: -1,
            studentRoleId: '-1',
            taskSubjectMatterLevelId: '-1',
            createdDate: new Date(),
            metadata: {
                taskId: task.id?.toString(),
                task: task,
                areaNode: task.meta?.subarea ?? '',
                assessment: isCorrect ? 'correct' : 'incorrect',
                secondsSpent: -1
            },
            selectedAnswer: selectedAnswer
        }
        // taskActivities.push(taskActivity)
        gameService.saveActivities([taskActivity]).then(response => {
            console.log('saved')
        }).catch(() => {
            taskActivities.push(taskActivity)
        })

        if (bonusPoints && studentId) {
            gameService.saveBonusScore(bonusPoints, studentId).then(response => {
                console.log('bonus saved')
            }).catch(() => {
                console.log('bonus not saved')
            })
        }
    }

    const uploadAnswers = async () => {
        if (taskActivities.length > 0) {
            gameService.saveActivities(taskActivities).then(response => {
                if (response.ok) {
                    // setTaskActivities([])
                    taskActivities = []
                    sessionStorage.removeItem('answers')
                }
            })
        }
    }

    return {
        taskActivities,
        saveScene,
        loadScene,
        saveChallenge,
        loadChallenge,
        saveAnswer,
        uploadAnswers,
    }
}