import React, { useState } from 'react';
import skinPng from './switch_skin-2.png'
import nodePng from './filter-setting-icon-2.png'
import PowerPng from './power-off-line-icon.png'
import onboardingPng from './onboarding.png'
import { useAuth } from 'providers/SignInProvider';
import IconButton from 'ux_components/icons';
import Tooltip from 'ux_components/tooltip';
import styles from './sideMenus.module.scss'
import overlayStyles from './SceneOverlay.module.scss'
import goldenArrowPng from './golden_arrow animated.png'
import banner from './banner.svg'


function Control(props: {
  className: string,
  skinSettingsCallback: () => void,
  nodeSettingsCallback: () => void,
  setShowOverlay: (value: boolean) => void,
  isInformationOverlay?: boolean,
  informationSpeechBubble?: { id: string, text: string }
}) {
  const [isLoggingOut, setIsLoggingOut] = useState(false)
  const [show, setShow] = useState(true)
  const auth = useAuth();

  const handleLogout = () => {
    if (isLoggingOut || props.isInformationOverlay || !!props.informationSpeechBubble) return;
    setIsLoggingOut(true)
    if (auth?.isLoggedIn)
      auth.logout().then(() => {
        setIsLoggingOut(false)
      })
  }

  return (
    <div className={props.className} style={{ marginLeft: '1%', textAlign: 'center' }} >
      <div id='controls_banner_div' className={`${styles.statsNamePlate} ${styles.hover} ${styles.hover_banner}`} onClick={props.isInformationOverlay ? () => { } : () => setShow(s => !s)}>
        {props.informationSpeechBubble?.id === 'controls_banner_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.left} ${overlayStyles.banner}`} >
          <div>{props.informationSpeechBubble.text}</div>
          <img src={goldenArrowPng} alt="golden arrow" />
        </div>}
        <img id='controls_banner_img' src={banner} alt='banner' className={``} />
        <div id='controls_banner_name' className={styles.bannerText}>Inställningar</div>
      </div>
      {<div className={show ? (styles.scores + ' ' + styles.show) : styles.scores} style={props.isInformationOverlay ? { zIndex: 0 } : {}}>
        <Tooltip {...{ id: 'info', text: props.isInformationOverlay ? '' : 'information', position: 'left', style: { marginTop: '10px' }, onClick: () => { props.setShowOverlay(true) } }}>
          <IconButton {...{ id: 'info_img', src: onboardingPng, alt: 'info button', large: true, clickable: true }} class={props.informationSpeechBubble && props.informationSpeechBubble?.id !== 'info_img' ? overlayStyles.iconButton : ''} />
          {props.informationSpeechBubble?.id === 'info_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.right}`} >{props.informationSpeechBubble.text}<img src={goldenArrowPng} alt="golden arrow" /></div>}
        </Tooltip>
        <Tooltip {...{ id: 'switch_def', text: props.isInformationOverlay ? '' : 'Byt spel', position: 'left', style: { marginTop: '10px' }, onClick: props.skinSettingsCallback }}>
          <IconButton {...{ id: 'switch_def_img', src: skinPng, alt: 'Change skin button', large: true, clickable: true }} class={props.informationSpeechBubble && props.informationSpeechBubble?.id !== 'switch_def_img' ? overlayStyles.iconButton : ''} />
          {props.informationSpeechBubble?.id === 'switch_def_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.right}`} >
            <div>{props.informationSpeechBubble.text}</div>
            <img src={goldenArrowPng} alt="golden arrow" />
          </div>}
        </Tooltip>
        <Tooltip {...{ id: 'select_node', text: props.isInformationOverlay ? '' : 'Välj Övningsområde', position: 'left', style: { marginTop: '10px' }, onClick: props.nodeSettingsCallback }}>
          <IconButton {...{ id: 'select_node_img', src: nodePng, alt: 'Change Subject matter button', large: true, clickable: true }} class={props.informationSpeechBubble && props.informationSpeechBubble?.id !== 'select_node_img' ? overlayStyles.iconButton : ''} />
          {props.informationSpeechBubble?.id === 'select_node_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.right}`} >
            <div>{props.informationSpeechBubble.text}</div>
            <img src={goldenArrowPng} alt="golden arrow" />
          </div>}
        </Tooltip>
        {auth?.isLoggedIn &&
          <Tooltip {...{ id: 'logout', text: props.isInformationOverlay ? '' : 'Logga ut', position: 'left', style: { cursor: !isLoggingOut ? 'pointer' : 'default', opacity: isLoggingOut ? '0.5' : '1', marginTop: '10px' }, onClick: () => handleLogout() }}>
            <IconButton {...{ id: 'logout_img', src: PowerPng, alt: 'Logout button', large: true, clickable: !isLoggingOut }} class={props.informationSpeechBubble && props.informationSpeechBubble?.id !== 'logout_img' ? overlayStyles.iconButton : ''} />
            {props.informationSpeechBubble?.id === 'logout_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.right}`} >
              <div>{props.informationSpeechBubble.text}</div>
              <img src={goldenArrowPng} alt="golden arrow" />
            </div>}
          </Tooltip>
        }
      </div>}
    </div>
  );
}

export default Control;
