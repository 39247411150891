import React, { useEffect, useState } from 'react';
import { GameObjectBase } from '../components/scene';
import { AreaNodeMasteries, GameEngine, GameState, initGame } from '../engine/game';
import { useAnimation, useEvent } from '../hooks';

import styles from './Play.module.scss';
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import gameService from 'services/gameService';
import { useStudent } from 'providers/SignInProvider';
import { AreaNode } from 'components/settings/AreaNodeSettings/area_nodes';
import SelectedGameMode from 'components/gameMode/gameMode';
import { Score } from 'components/sideMenus/Stats';
import { GameObjectModel } from 'engine/models/gameObject';
import runningKnight from './knight_running.png'
import InformationOverlay from './InformationOverlay/InformationOverlay';
import DifficultyModal from './DifficultyModal/DifficultyModal';

function Play() {

  // trigger game to start
  const [start, setStart] = useState(true);

  // if game is running
  const [started, setStarted] = useState(false);

  // instance of game engine
  const [engine, setEngine] = useState<GameEngine | null>(null);

  // game state
  const [gameState, setGameState] = useState<GameState | null>(null);

  const [score, setScore] = useState<Score>({ score: 0, scoreInARow: -1, bestScoreInARow: -1, loginStreak: 0 })
  const [nodes, setNodes] = useState<AreaNode[]>()
  const [masteries, setMasteries] = useState<AreaNodeMasteries>()
  const [showOverlay, setShowOverlay] = useState<boolean>(false)

  const student = useStudent()
  const gameGlobals = engine?.getGameGlobals()

  function GameObject({ gameObject }: { gameObject: GameObjectModel }) {
    return <GameObjectBase gameObject={gameObject} onEvent={() => { }} imageUrl={`./assets/${gameObject.avatar}.svg`} />
  }

  const handleScore = async (value: number, bonusPoints?: boolean) => {
    const tempScore = Object.assign({}, score)
    tempScore.score += value
    // setScore(s => ({ ...s, score: value }))
    if (student && student.id) {
      if (value > 0 && bonusPoints !== true) {
        tempScore.scoreInARow++
        gameService.saveStreakScore(+tempScore.scoreInARow, student.id.toString(), 'current_streak', false).then(result => { })
        if (tempScore.scoreInARow > tempScore.bestScoreInARow) {
          tempScore.bestScoreInARow = tempScore.scoreInARow
          gameService.saveStreakScore(tempScore.scoreInARow, student.id.toString(), 'streak', true).then(result => { })
        }
        if (tempScore.scoreInARow % 5 === 0) {
          engine?.actions.completeEvent({ type: 'celebration', object: 'fire-streak' })
        }
        if (tempScore.score % 100 === 0) {
          engine?.actions.completeEvent({ type: 'celebration', object: 'trophy' })
        }
      }
      else tempScore.scoreInARow = 0
    }
    setScore(tempScore)
  }

  useAnimation(elapsedTime => {
    if (engine != null) {
      setGameState(engine.tick(elapsedTime));
    }
  }, [engine]);

  const handleKeyPress = (e: any) => {

    // the ' ' char actually represents the space bar key.
    if (e.key === ' ' && !started && !start) {
      setStart(true);
    }

    // if the game has not been initialized return
    if (engine === null) return;

    engine.keyEvent({ type: 'up', key: 'ESC' }); // TODO!
  };

  useEvent('keyup', handleKeyPress);

  const checkGameMode = () => {
    if (!engine) return
    const gameMode = gameGlobals?.gameMode
    if (gameMode) {
      if (gameMode.isTimed) {
        return !((gameGlobals.timer ?? 0) >= gameMode.startTime + (gameMode.timeLimit * 1000))
      }
    }
    return true
  }

  useEffect(() => {
    if (start) {
      setStarted(true);
      setStart(false);
      initGame(student).then(engine => {
        setEngine(engine)

      });
    }
  }, [start]);

  useEffect(() => {
    if (student && student.id)
      gameService.getStudentScore(student.id.toString()).then(result => {
        setScore({ score: (+result.score ?? 0) + (+result.bonusPoints ?? 0), scoreInARow: +result.currentStreak ?? 0, bestScoreInARow: +result.topStreak ?? 0, loginStreak: +result.loginStreak ?? 0 })
      })
  }, [student])

  useEffect(() => {
    let running = true;
    if (!nodes && gameGlobals) {
      setNodes(gameGlobals?.areaNodes)
    }
    return () => { running = false; };
  }, [nodes, gameGlobals, gameGlobals?.areaNodes])

  useEffect(() => {
    let running = true;
    if (!masteries && gameGlobals) {
      setMasteries(gameGlobals?.masteries)
    }
    return () => { running = false; };
  }, [masteries, gameGlobals, gameGlobals?.masteries])

  return (
    <div className={styles.gridContainer}>
      <div className={styles.gridLeft}></div>
      <div className={styles.gridRight}></div>
      <div className={styles.gridBottom}></div>
      <div id="play" className={styles.container}>
        {engine && gameState ? <GameGlobalsContext.Provider value={engine.getGameGlobals()}>
          <SelectedGameMode {...{ mode: gameGlobals?.gameMode, engine, state: gameState, nodes: nodes ?? [], masteries: masteries ?? {}, score, setScore: handleScore, showOverlay, setShowOverlay }} />
        </GameGlobalsContext.Provider> : <img style={{ width: '50%', margin: 'auto', position: 'absolute', inset: 0 }} src={runningKnight} alt='running knight' />}
      </div>
      {gameState && gameState.outsideObjects.map(go => <GameObject key={go.id} gameObject={go} />)}
      <InformationOverlay showOverlay={showOverlay} setShowOverlay={setShowOverlay} />
      <DifficultyModal />
    </div>
  );
}

export default Play;
