import React, { ReactNode } from "react";
import styles from "./Tooltip.module.scss";

const Tooltip = (props: { id: string, children: ReactNode, text: string, position: 'left' | 'right', style: React.CSSProperties, onClick: () => void }) => {
    return <div id={props.id+'_div'} className={styles.tooltip} style={props.style} onClick={props.onClick}>
        {props.children}
        {props.text && <span id={props.id+'tooltip'} className={`${styles.tooltiptext} ${props.position === 'left' ? styles.tooltiptext_left : styles.tooltiptext_right}`}>{props.text}</span>}
    </div>
}

export default Tooltip;