import { useAuth, useStudent } from 'providers/SignInProvider';
import React, { useEffect, useReducer, useState } from 'react';
import profileService from 'services/profileService';
import { StudentSessionContext } from 'services/sessionService';
import styles from "./Account.module.css";
import goldenArrowAnimated from 'components/challenge/golden_arrow animated.png'

/** Whether we know where the student is studying. */
const hasReliableSchoolData = (student: StudentSessionContext): boolean => {
    return student.studentGroups.length > 0;
}

const Input = (props: { label: string; value: string; placeholder: string; onInput: (value: string) => void, isRow: boolean, width: string | undefined, },): JSX.Element => {
    if (props.isRow) {
        return (
            <label className={styles.label} style={props.width ? { width: props.width } : {}}>
                <h2>{props.label}</h2>
                <input type="text" placeholder={props.placeholder} value={props.value} onInput={(e) => props.onInput(e.currentTarget.value)} />
            </label>
        )
    } else {
        return (
            <input type="text" role={props.label} style={props.width ? { width: props.width } : {}} placeholder={props.placeholder} value={props.value} onInput={(e) => props.onInput(e.currentTarget.value)} />
        )
    }

}

type UserPlayerSettings =
    | null
    | { nickname: string; }

type UserSchoolMarketing =
    // | null
    | { hasSchool: boolean; school: string; class: string; }
// | { hasSchool: true; school: string; class: string; }

type UserPlayerSettingsAction =
    | { type: 'SET_NICKNAME'; value: Exclude<UserPlayerSettings, null>['nickname']; }

const userPlayerSettingsReducer = (state: UserPlayerSettings, action: UserPlayerSettingsAction): UserPlayerSettings => {
    switch (action.type) {
        case 'SET_NICKNAME':
            return { ...state, nickname: action.value };
    }
}

const UserPlayerSettingsInput = (props: {
    state: UserPlayerSettings;
    dispatch: React.Dispatch<UserPlayerSettingsAction>;
}): JSX.Element => {
    return (
        <div className={styles.container}>
            <Input label="Vad ska vi kalla dig?" placeholder='Namn' value={props.state?.nickname ?? ''} isRow={true} width={undefined} onInput={(value) => props.dispatch({ type: 'SET_NICKNAME', value })} />
        </div>
    );
}

type UserSchoolMarketingAction =
    // | { type: 'SET'; value: UserSchoolMarketing }
    | { type: 'SET_HAS_SCHOOL'; value: Extract<UserSchoolMarketing, { hasSchool: unknown }>['hasSchool']; }
    | { type: 'SET_SCHOOL'; value: Extract<UserSchoolMarketing, { school: unknown }>['school']; }
    | { type: 'SET_CLASS'; value: Extract<UserSchoolMarketing, { class: unknown }>['class']; }

const userSchoolMarketingReducer = (state: UserSchoolMarketing | null, action: UserSchoolMarketingAction): UserSchoolMarketing => {
    switch (action.type) {
        // case 'SET':
        //     return null;
        case 'SET_HAS_SCHOOL':
            return action.value ? { hasSchool: true, school: '', class: '' } : { hasSchool: false, school: '', class: '' };
        case 'SET_SCHOOL':
            return { class: '', ...state, hasSchool: true, school: action.value };
        case 'SET_CLASS':
            return { school: '', ...state, hasSchool: true, class: action.value };
    }
}

const UserSchoolMarketingInput = (props: {
    state: UserSchoolMarketing;
    dispatch: React.Dispatch<UserSchoolMarketingAction>;
}): JSX.Element => {
    if (!props.state) {
        return (
            <div className={styles.container}>
                <h2>Går du i någon skola?</h2>
                <button type="button" onClick={() => props.dispatch({ type: 'SET_HAS_SCHOOL', value: false })}>
                    Jag går inte i någon skola
                </button>
                <button type="button" onClick={() => props.dispatch({ type: 'SET_HAS_SCHOOL', value: true })}>
                    Jag går i en skola
                </button>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h2>Vilken skola och klass går du i?</h2>
            <Input label="Skola" value={props.state.school} placeholder="Skola" width='50%' isRow={false} onInput={(value) => props.dispatch({ type: 'SET_SCHOOL', value })} />
            <Input label="Klass" value={props.state.class} placeholder="Klass" width='10%' isRow={false} onInput={(value) => props.dispatch({ type: 'SET_CLASS', value })} />

            <div style={{ marginTop: '25px' }}>
                <input style={{ width: '20px', height: '20px', marginRight: '15px' }} id="hasSchool" type="checkbox" onInput={(e) => { props.dispatch({ type: 'SET_HAS_SCHOOL', value: !props.state.hasSchool }) }}
                    checked={!props.state.hasSchool} />
                <label htmlFor="hasSchool" style={{ fontSize: '18px' }}>Jag går inte i skolan</label>
            </div>
        </div>
    );
}

const Account = (): JSX.Element | null => {
    const student = useStudent();
    const [playerSettings, dispatchPlayerSettings] = useReducer(userPlayerSettingsReducer, student?.playerSettings ?? null);
    const [schoolMarketing, dispatchSchoolMarketing] = useReducer(userSchoolMarketingReducer, student?.schoolMarketing ?? { hasSchool: true, school: '', class: '', });
    const [isSaving, setIsSaving] = useState(false);
    const [hasInvite, setHasInvite] = useState(false)

    const auth = useAuth();

    const save = async () => {
        setIsSaving(true);
        await profileService.setProfile({
            data: {
                playerSettings,
                schoolMarketing: { ...schoolMarketing, hasSchool: schoolMarketing.hasSchool && schoolMarketing.school.trim() !== '' ? true : false },
            }
        }).finally(() => {
            setIsSaving(false)
            // TODO: integrate this more nicely than a full reload
            window.location.reload();
        });
    }

    const checkIfDisabled = (): boolean => {
        if (isSaving) return true
        return !playerSettings || playerSettings?.nickname.trim() === '' ? true : false
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const paramValue = params.get("invite") ?? undefined;

        if (paramValue) {
            profileService.getGroupByInviteCode(paramValue).then(result => console.log('result', result))
            setHasInvite(true)
        }
    }, [])

    return <div className={styles.container}>
        {auth?.isLoggedIn &&
            <button style={
                {
                    background: 'none',
                    border: 'none',
                    margin: '0',
                    padding: '0',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    color: '#0060df'
                }
            } onClick={() => {
                auth.logout().then(() => { })
            }}>Logga ut</button>
        }
        <UserPlayerSettingsInput state={playerSettings} dispatch={dispatchPlayerSettings} />
        {hasInvite || (student && hasReliableSchoolData(student)) || <UserSchoolMarketingInput state={schoolMarketing} dispatch={dispatchSchoolMarketing} />}

        <br />

        <button id='signup_save_button' disabled={checkIfDisabled()} className={styles.arrowButton} onClick={save}>
            <img src={goldenArrowAnimated} alt="Spara" />
        </button>
    </div>
}

export default Account;
