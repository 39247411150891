import React, { useState } from "react";
import styles from "./ReportBug.module.scss";
import goldenArrowAnimated from './golden_arrow animated.png'

const ReportBug = (props: { isActive: boolean, setIsActive: (newValue: boolean) => void, sending: boolean, handleSendBugReport: (message: string) => Promise<void>, taskId: string }) => {

    const [message, setMessage] = useState('');

    const handleSendBugReport = (message: string) => {
        if (props.sending) return
        props.handleSendBugReport(message).finally(() => {
            setMessage('');
            props.setIsActive(false);
        });
    }

    return (
        <div className={`${styles.reportBug} ${props.isActive ? styles.active : ''}`}>
            <img id='report_bug_close_button' className={styles.closeButton} src='./assets/close-with-background.png' alt="Close button" data-testid='report_bug_close_button' onClick={() => props.setIsActive(false)} />

            <div className={styles.fieldHeadline}>Skapa felrapport</div>
            <div className={styles.fieldText}>Frågeid: {props.taskId.length > 7 ? `...${props.taskId.substring(props.taskId.length - 5)}` : props.taskId}</div>
            <textarea value={message} className={styles.fieldTextArea} onChange={event => setMessage(event.target.value)} /> <br />
            <img onClick={() => handleSendBugReport(message)} src={goldenArrowAnimated} alt="golden arrow" className={`${styles.arrow} ${props.sending ? styles.sending : ''}`} />
        </div>
    );
}

export default ReportBug;