import React, { CSSProperties } from 'react';
import styles from './Icons.module.scss'

const IconButton = (props: { id: string, src: string, alt: string, large: boolean, clickable: boolean, class?: string, style?: CSSProperties, text?: string }) => {
    return <div 
        className={` ${styles.iconStyle} ${props.large ? styles.large : ''} ${styles.hover} ${styles.hover_icon} ${props.clickable ? styles.clickable : ''} ${props.class ?? ''} ${props.text ? styles.withText : ''}`}             
        style={props.style}
    >
        <img id={`${props.id}_img`}
            src={props.src} alt={props.alt}
        />
        {props.text && <div className={styles.iconText} id={`${props.id}_text`} style={{ textAlign: 'center', fontFamily: 'Architects Daughter, cursive'}}>
            {props.text}
        </div>}
    </div>
}

export default IconButton;