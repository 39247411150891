import React, { useEffect, useState } from 'react';
import { FillGapsTaskDefinition, TaskImplProps } from '.';
import goldenArrowAnimated from './golden_arrow animated.png'
import { WriteSentence } from './TaskUtils';

const ANSWER_KEY = 'ANSWER_KEY';

type FillGapsSegment = { type: 'word' | 'gap'; value: string; }

type FillGapsEngine = {
  segments: () => FillGapsSegment[];
  options: () => string[];
  isAnswerValid: (words: string[]) => boolean;
  getCorrectAnswer: () => string;
}

// TODO: shuffle (using a seeded PRNG with a constant seed would maintain the order between renders)
const shuffleOptions = (data: string[]) => {
  const shuffled = []
  const dataToShuffle = [...data]
  for (let i = 0; i < data.length; i++) {

    const index = Math.floor(Math.random() * dataToShuffle.length);
    shuffled.push(dataToShuffle[index]);
    dataToShuffle.splice(index, 1);
  }
  return shuffled;
}

const completedFillGapsEngine = (data: FillGapsTaskDefinition, givenAnwers: {id: number, value: string}[]): FillGapsEngine => {
  const segments = data.words.map<FillGapsSegment>((word, index) => ({ type: 'word', value: givenAnwers.find(a => a.id === index)?.value ?? word }));

  return {
    segments: () => { return segments },
    options: () => [],
    isAnswerValid: () => { return true},
    getCorrectAnswer: () =>{ return ''}
  }
}

export const getCorrectAnswer = (data: FillGapsTaskDefinition) => data.words.map((w, index) => data.gaps[index] ? `[${w}]` : w).join(' ')

const fillGapsEngine = (data: FillGapsTaskDefinition): FillGapsEngine => {
  const segments = data.words.map<FillGapsSegment>((word, index) => ({ type: data.gaps[index] ? 'gap' : 'word', value: word }));
  const missingWords = segments.filter(({ type }) => type === 'gap').map(({ value }) => value);
  const unShuffledOptions = (data.options.concat(missingWords))
    .filter((v, i, a) => a.slice(0, i).every(v2 => v2 !== v)) // deduplicate
  const shuffledOptions = shuffleOptions(unShuffledOptions);

  return {
    segments: () => { return segments },
    options: () => shuffledOptions,
    isAnswerValid: (words) => words.join(' ') === data.words.join(' '),
    getCorrectAnswer: () => data.words.map((w, index) => data.gaps[index] ? `[${w}]` : w).join(' ')
  }
}

const initialAnswer = (data: FillGapsTaskDefinition) => data.words.map((word, index) => data.gaps[index] ? '' : word);

function FillGaps({ data, onCompletion, showInstructions }: TaskImplProps) {
  if (data.type !== 'FillGaps') {
    throw new Error(`Unexpected type ${data.type}`);
  }

  const [words, setWords] = useState<string[]>([]);
  useEffect(() => { setWords(initialAnswer(data)); setSelectedAnswers([]) }, [data]);
  const [selectedAnswers, setSelectedAnswers] = useState<{ id: number, value: string }[]>([]);

  const setWord = (index: number, value: typeof words[number]) => {
    const ind = selectedAnswers.findIndex(answer => answer.id === index)
    setSelectedAnswers(answers => ind === -1 ? [...answers, { id: index, value }] : answers.map((v, i) => i !== ind ? v : { id: index, value }));
    setWords(words => words.map((v, i) => i !== index ? v : value));
  }

  const [engine, setEngine] = useState<FillGapsEngine | null>(null);
  useEffect(() => {setEngine(fillGapsEngine(data))}, [data]);

  const handleCompletion = (engine: FillGapsEngine) => {
    onCompletion(
      data,
      engine.isAnswerValid(words) ? 1 : 0,
      engine.getCorrectAnswer(),
      data.nodeRules ?? '',
      words.filter((word, index) => data.gaps[index]).join(',')
    )
    setEngine(completedFillGapsEngine(data, selectedAnswers))
    setWords([])
    setSelectedAnswers([])
  }

  if (engine == null) {
    return <></>;
  }

  return (
    <>
      {showInstructions && <h2 className='header' style={{fontFamily: 'Architects Daughter, cursive'}}>Välj rätt ord</h2>}
      <div>
        {engine.segments().map((segment, index) => segment.type === 'gap'
          ? (<span key={index}>
            <select
              defaultValue={''}
              // value={selectedAnswers.find(answer => answer.id === index)?.value ?? ''}
              onDragOver={event => event.preventDefault()}
              onDrop={event => setWord(index, event.dataTransfer.getData(ANSWER_KEY))}
              onChange={event => setWord(index, event.target.value)}
            >
              <option key={-1} value='' hidden/>
              {engine.options().filter(o => o.trim() !== '').map((value, index) => <option key={index} value={value}>{value}</option>)}
            </select> </span>)
          : (<span key={index}><WriteSentence sentenceFragment={segment.value} /> </span>)
        )}
      </div>


      <br />
      <img src={goldenArrowAnimated} alt='Next arrow' style={{ minWidth: '5%', width: '50px', maxWidth: '10%' }}
        onClick={() => handleCompletion(engine)} />
    </>
  );
}

export default FillGaps;