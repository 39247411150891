export type SetProfileRequest = {
    playerSettings: null | { nickname: string; }
    schoolMarketing: null | { hasSchool: false; } | { hasSchool: true; school: string; class: string; }
}

async function setProfile(params: { data: SetProfileRequest; abortSignal?: AbortSignal }): Promise<void> {
    await fetch(`${process.env.REACT_APP_API_BASE}/game/profile`, {
        method: 'PUT',
        credentials: 'include',
        signal: params.abortSignal,
        body: JSON.stringify(params.data),
    })
}

async function getGroupByInviteCode(inviteCode: string) {
    const result = await fetch(`${process.env.REACT_APP_API_BASE}/groups/invite?invite=${inviteCode}`,
    {
        credentials: 'include'
    })
    return result
}

async function setUserDifficulty(difficulty: { min: string, max: string }) {
    const result = await fetch(`${process.env.REACT_APP_API_BASE}/user/difficulty`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ difficulty })
    })
    return result.json();
}

export const profileService = {
    setProfile,
    getGroupByInviteCode,
    setUserDifficulty,
};

export default profileService;
