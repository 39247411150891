import React, { useEffect, useState } from 'react';
import style from './Login.module.css';
import logo from './../logo.svg'
import goldenArrowAnimated from 'components/challenge/golden_arrow animated.png'
import { DemoScene } from 'components/scene';
import gameService from 'services/gameService';
import GameDefinition, { SceneDefinition } from 'engine/models/gameDefinition';
import { initSaveStateHandler } from 'engine/saveStateHandler';
import { EmptyGameActionHandlers } from 'engine/game';
import createScene, { SceneEngine, SceneModel } from 'engine/scenes';
import { AuthContext } from 'providers/SignInProvider';
import googleLoginPng from './btn_google_signin_dark_normal_web.png'
import microsoftLoginPng from './ms-symbollockup_signin_dark.png'

function Login(props: { auth: AuthContext | null }) {
  const params = new URLSearchParams(window.location.search);
  const invite = params.get("invite") ?? undefined;
  const [sceneModel, setSceneModel] = useState<SceneModel>()
  const [loadingBar, setLoadingBar] = useState('')
  const demoAdventure = 'battleground'
  const demoTheme = 'fantasy'
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    let isMounted = true;
    gameService.getGameDefinition(demoAdventure, demoTheme).then(gm => {
      if (isMounted) {
        const scene = createScene(gm.scenes[1], gm.player, EmptyGameActionHandlers, initSaveStateHandler())
        scene.tick(1)
        setSceneModel(scene.tick(3500))
      }
    })
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    let isMounted = true;
    if (!props.auth)
      new Promise(() => {
      }).then(() => {
        if (isMounted) {
          setTimeout(() => {
            setLoadingBar(lb => lb + '#')
          }, 1000);
        }
      })
    return () => { isMounted = false }
  }, [loadingBar, props.auth])

  const ShowDemo = () => {
    return <div style={{ justifyContent: 'center', textAlign: 'center' }}>
      <div><img className={style.logo} src={logo} alt='logo' /></div>
      <div className={style.container}>
        {sceneModel && <DemoScene {...{ sceneModel, adventure: demoAdventure, theme: demoTheme, }} />}
      </div>
      {props.auth ? <div className={style.bottomRow} >
        <h5 style={{ color: '#15141A', marginTop: '0', marginBottom: '5vh' }}>Börja spela</h5>
        {!showLogin && <img className={style.goldenArrow} src={goldenArrowAnimated} alt="Golden arrow" />}
        {showLogin && <div className={style.loginBox}>
          <span style={{ width: '100px' }}>
            <a href={`${process.env.REACT_APP_API_BASE}/session/init/oidc/google/spel${invite ? `?invite=${invite}` : ''}`}>
              <img className={''} style={{maxHeight:'41px', textAlign: 'center'}} src={googleLoginPng} alt="Google login" />
            </a>
          </span>
          <span style={{ width: '100px' }}>
            <a href={`${process.env.REACT_APP_API_BASE}/session/init/oidc/microsoft/spel${invite ? `?invite=${invite}` : ''}`}>
              <img className={''} style={{maxHeight:'41px', textAlign: 'center'}} src={microsoftLoginPng} alt="Microsoft login" />
            </a>
          </span>
        </div>}
      </div> : <div className={style.bottomRow + ' text-right'}>Laddar {loadingBar}</div>}

    </div>
  }

  return (
    <div className="Login">
      {props.auth ? <div onClick={() => setShowLogin(() => true)}>
        <ShowDemo ></ShowDemo>
      </div> : <div><ShowDemo></ShowDemo></div>}
    </div >);
}

export default Login;
