import { GameAction, GameActionHandlers, GameEventHandler, GameObjectStateValue, PointerEventHandler } from "../game";
import { ChallengerDefinition, GameObjectDefinition, GameObjectMessageDefinition, GameObjectPosition, PlayerDefinition, SceneDefinition, TransitionPointDefinition } from "../models/gameDefinition";
import { GameObjectStateUpdate, SceneMechanics, SceneMechanicsDashboard, SceneMechanicsAssignmentRoom, SceneMechanicsStaticSideviewLandscape, Movement } from "../models/sceneMechanicsDefinition";
import { SaveStateHandler } from "engine/saveStateHandler";
import { GameObjectModel, GameObjectState, MessageModel, TimedGameObjectState, TransitionPointState } from "engine/models/gameObject";
import { Animation, setupFade, setupMove, setupMoveToPossessions, updateAnimations } from "engine/animation";
import TagManager from 'react-gtm-module'

export interface SceneModel {
    id: string;
    background: string;
    gameObjects: GameObjectModel[];
}


export interface SceneEngine {
    tick: (elapsedTime: number) => SceneModel;
    gameEvent: GameEventHandler;
    pointerEvent: PointerEventHandler;
    completeChallenge: (challengerId: string) => void;
    challengesRemaining: () => number;
}

function mapMessage(definition: GameObjectMessageDefinition | null): MessageModel | null {
    if (definition === null) return null;

    return {
        text: definition.text,
        direction: definition.direction,
        anchorX: definition.anchor.x,
        anchorY: definition.anchor.y,
        width: definition.width
    };
}

function mapSimple(definition: GameObjectDefinition): GameObjectState {
    return {
        id: definition.id,
        avatar: definition.avatar,
        clickable: false,
        height: definition.size.height,
        width: definition.size.width,
        xPos: definition.startPos.x,
        yPos: definition.startPos.y,
        zPos: definition.startPos.z,
        opacity: definition.initialOpacity ?? 1,
        message: mapMessage(definition.message),
        state: definition.initialState,
        click: []
    };
}

function mapChallenger(definition: ChallengerDefinition): GameObjectState {
    return {
        ...mapSimple(definition),
        click: ['startChallenge'],
        clickable: definition.initialState === 'active',
        timeLimit: definition.challenge.type === 'timed' ? definition.challenge.timeLimit : undefined
    };
}

function mapTransitionPoint(definition: TransitionPointDefinition): TransitionPointState {
    return {
        ...mapSimple(definition),
        clickable: definition.initialState === 'active',
        click: ['switchScene'],
        targetScene: definition.target.scene,
        targetPlayerPosition: definition.target.position
    };
}

const behaviourStateCreators: {
    [behaviour in GameObjectDefinition['behaviour']]: (gameObject: Extract<GameObjectDefinition, { behaviour: behaviour }>) => GameObjectState
} = {
    challenger: mapChallenger,
    thing: mapSimple,
    helper: mapSimple,
    feature: mapSimple,
    transition: mapTransitionPoint,
    player: mapSimple,
};

function createGameObjectState(def: GameObjectDefinition): GameObjectState {
    // cast to function of any GameObjectDefinition so the value does not need to be assignable to every GameObjectDefinition
    // since we get the map function based on the behaviour, we know the value has the behaviour the map function expects
    const createState = behaviourStateCreators[def.behaviour] as (gameObject: GameObjectDefinition) => GameObjectState;
    return createState(def);
}



function applyStateUpdates(objectStates: GameObjectState[], stateUpdates: GameObjectStateUpdate[], animations: Animation[], currentTime: number) {
    for (const update of stateUpdates) {
        const obj = objectStates.find(o => o.id === update.id);
        if (!obj) {
            // TODO: Crash in testing, report error and move on in production
            throw new Error('Error in game definition!');
        }

        obj.click = update.click;
        obj.state = update.state;
        obj.message = mapMessage(update.message);
        obj.clickable = obj.state === 'active' && obj.click.length > 0;

        if (update.move) {
            animations.push(setupMove(obj, update.move, currentTime));
        }

        if (update.fade) {
            animations.push(setupFade(obj, update.fade, currentTime));
        }
    }
}

function checkTime(timedState: TimedGameObjectState, elapsedtime: number) {
    if (timedState.timeLimit + timedState.startTime < + elapsedtime) {
        timedState.state = 'done'
        return true
    }
    return false;
}

function dashboard(scene: SceneDefinition, player: PlayerDefinition, actions: GameActionHandlers, saveStateHandler: SaveStateHandler): SceneEngine {

    const mechanics: SceneMechanicsDashboard | null = scene.mechanics.type === 'dashboard' ? scene.mechanics : null;
    // const sequence = mechanics?.assignment || []; 
    const numberOfSteps = 0; //sequence.length; 

    let stepIndex = 0;
    let appliedCurrentStateStepAt = 0;
    let applyNextStateStepAt = 0;
    let lastTick = 0;

    const objectStates = [player, ...scene.objects].map(createGameObjectState);
    const stateMap = objectStates.reduce<{ [key: string]: GameObjectState }>((map, objState) => {
        map[objState.id] = objState;
        return map;
    }, {});

    function enqueNextStateStep() {
        if (stepIndex < numberOfSteps && appliedCurrentStateStepAt >= applyNextStateStepAt) {
            // TODO: This introduces a slight lag; should refactor to measure running time inside this method but without messing up testing. 
            // But, hopefully, this is good enough for the lifetime of this prototype
            //            applyNextStateStepAt = lastTick + sequence[stepIndex].delay; 
        }
    }

    function applyNextStateStep(elapsedTime: number) {
        // const step = sequence[stepIndex]; 
        // step.stateUpdates.forEach(update => {
        //     const obj = objectStates.find(o => o.id === update.id); 
        //     if (!obj) {
        //         // TODO: Crash in testing, report error and move on in production
        //         throw 'Error in game definition!';                         
        //     }
        //     if (!!obj.click) {
        //         obj.click = update.click; 
        //     }
        //     obj.state = update.state; 
        //     obj.message = mapMessage(update.message); 
        //     obj.clickable = obj.click.length > 0; 
        // }); 

        // appliedCurrentStateStepAt = elapsedTime; 
        // stepIndex++; 
    }

    return {
        tick: (elapsedTime: number) => {
            // Initialization at first tick
            if (appliedCurrentStateStepAt < 0) {
                appliedCurrentStateStepAt = elapsedTime;
                //                applyNextStateStepAt = numberOfSteps > 0 ? appliedCurrentStateStepAt + sequence[0].delay : 0;                
            }

            if (elapsedTime >= applyNextStateStepAt && numberOfSteps > stepIndex) {
                applyNextStateStep(elapsedTime);
            }

            lastTick = elapsedTime;

            return {
                id: scene.id,
                background: scene.background,
                gameObjects: objectStates
            };
        },

        completeChallenge(challengerId) {
            // TODO
        },

        challengesRemaining: () => 0,

        gameEvent: (event) => {
            // TODO

        },

        pointerEvent: (event) => {
            if (event.type === 'click' && event.target) {
                const targetState = stateMap[event.target.id];
                if (targetState.click.length > 0) {
                    targetState.click.forEach(toDo => {
                        switch (toDo) {
                            case 'next':
                                enqueNextStateStep();
                                break;
                            case 'pick':
                                // TODO! 
                                break;
                            case 'toggleMessage':
                                // TODO! 
                                break;
                            case 'switchScene':
                                break;
                            default:
                                // Ignore
                                // TODO: Report? Error? 
                                throw 'TODO! Handle unsupported pointer event';
                        }
                    });
                }
            }
        }
    };
}

function assignmentRoom(scene: SceneDefinition, player: PlayerDefinition, actions: GameActionHandlers, saveStateHandler: SaveStateHandler): SceneEngine {
    // player and assignee; assignee delivers a message and perhaps some items

    const mechanics: SceneMechanicsAssignmentRoom | null = scene.mechanics.type === 'assignmentRoom' ? scene.mechanics : null;

    let sequence = mechanics?.assignment || [];

    let stepIndex = 0;
    let appliedCurrentStateStepAt = -1;
    let applyNextStateStepAt = 0;
    let lastTick = 0;

    const objectStates = [player, ...scene.objects].map(createGameObjectState);
    const stateMap = objectStates.reduce<{ [key: string]: GameObjectState }>((map, objState) => {
        map[objState.id] = objState;
        return map;
    }, {});

    const animations: Animation[] = [];

    function enqueNextStateStep() {
        if (stepIndex < sequence.length && appliedCurrentStateStepAt >= applyNextStateStepAt) {
            // TODO: This introduces a slight lag; should refactor to measure running time inside this method but without messing up testing. 
            // But, hopefully, this is good enough for the lifetime of this prototype
            applyNextStateStepAt = lastTick + sequence[stepIndex].delay;
        } else if (stepIndex === sequence.length && sequence === mechanics?.debriefing) {
            // special case: complete the quest when calling next at the end of debriefing
            actions.completeQuest();
        }
    }

    function applyNextStateStep(elapsedTime: number) {
        if (stepIndex >= sequence.length) return;

        const step = sequence[stepIndex];

        applyStateUpdates(objectStates, step.stateUpdates, animations, elapsedTime);

        appliedCurrentStateStepAt = elapsedTime;
        applyNextStateStepAt = -1;
        stepIndex++;

        if (step.autonext) {
            enqueNextStateStep();
        }
    }

    return {
        tick: (elapsedTime: number) => {

            // Initialization at first tick
            if (appliedCurrentStateStepAt < 0) {
                appliedCurrentStateStepAt = elapsedTime;
                lastTick = elapsedTime;
                enqueNextStateStep();
            }

            if (applyNextStateStepAt >= 0 && elapsedTime >= applyNextStateStepAt) {
                applyNextStateStep(elapsedTime);
            }

            updateAnimations(animations, elapsedTime);
            lastTick = elapsedTime;

            return {
                id: scene.id,
                background: scene.background,
                gameObjects: objectStates.filter(oS => oS.state !== 'unpresent')
            };
        },

        completeChallenge(challengerId) {
            // TODO
        },

        challengesRemaining: () => 0,

        gameEvent: (event) => {
            switch (event.type) {
                case 'questCompleted':
                    sequence = mechanics?.debriefing || [];
                    stepIndex = 0;
                    enqueNextStateStep();
                    break;
                case 'celebration':
                    const movement: Movement = { to: { x: 50, y: -5, z: 12 }, easing: 'linear', speed: 0.05 }
                    const celebration = createGameObjectState({ avatar: event.object, behaviour: 'thing', challenge: null, id: 'celebration', startPos: { x: 50, y: 100, z: 12 }, size: { height: 10, width: 10 }, message: null, initialState: 'unpresent' })
                    animations.push(setupMove(celebration, movement, lastTick))
                    break;

            }
        },

        pointerEvent: (event) => {
            if (event.type === 'click' && event.target) {
                const targetState = stateMap[event.target.id];
                if (targetState.click.length > 0) {
                    targetState.click.forEach(toDo => {
                        switch (toDo) {
                            case 'next':
                                enqueNextStateStep();
                                break;
                            case 'pick':
                                animations.push(setupMoveToPossessions(targetState, lastTick));
                                break;
                            case 'switchScene':
                                actions.switchScene((targetState as TransitionPointState).targetScene, (targetState as TransitionPointState).targetPlayerPosition);
                                break;
                            case 'toggleMessage':
                                // TODO!!!
                                break;
                            default:
                                throw 'TODO! How to handle unknown click type assigned?'; // TODO: Report? Error? 
                        }
                    });
                }
            }
        }
    };
}

function staticSideviewLandscape(scene: SceneDefinition, player: PlayerDefinition, actions: GameActionHandlers, saveStateHandler: SaveStateHandler, loadedState?: string): SceneEngine {
    const expectedMechanicsType: typeof mechanics['type'] = 'staticSideviewLandscape';
    if (scene.mechanics.type !== expectedMechanicsType) {
        throw new Error(`Expected mechanics of type ${expectedMechanicsType}`);
    }

    const mechanics: SceneMechanicsStaticSideviewLandscape = scene.mechanics;

    let lastTick = 0;

    const definitionMap = [player, ...scene.objects].reduce<Record<GameObjectDefinition['id'], GameObjectDefinition>>((map, obj) => {
        map[obj.id] = obj;
        return map;
    }, {});
    const objectStates = [player, ...scene.objects].map(createGameObjectState);
    const stateMap = objectStates.reduce<{ [key: string]: GameObjectState }>((map, objState) => {
        map[objState.id] = objState;
        return map;
    }, {});

    const animations: Animation[] = [];

    const challengerStates = objectStates.filter(s => scene.objects.some(o => s.id === o.id && o.behaviour === 'challenger'));

    return {
        tick: (elapsedTime: number) => {
            if (lastTick === 0) {
                // first tick

                if (loadedState) { // TODO: handle loaded state better
                    const LastCompletedChallenge = mechanics.onChallengeCleared[loadedState]
                    mechanics.onFirstEnter.forEach(o => {
                        const completed = LastCompletedChallenge?.find(lc => lc.id === o.id)
                        if (completed) { o.state = completed.state }
                    })
                    const filteredOnEnter = LastCompletedChallenge ? mechanics.onFirstEnter.filter(o => !challengerStates.some(cs => cs.id === o.id)) : mechanics.onFirstEnter
                    applyStateUpdates(objectStates, filteredOnEnter, animations, elapsedTime);
                }
                else applyStateUpdates(objectStates, mechanics.onFirstEnter, animations, elapsedTime);
                challengerStates.forEach(cs => { if (cs.timeLimit && !cs.startTime) { cs.startTime = elapsedTime } })

            }
            const startedTimedChallenges = challengerStates.filter(cs => cs.startTime && cs.timeLimit) as TimedGameObjectState[]
            startedTimedChallenges.forEach(cs => {
                if (checkTime(cs, elapsedTime)) {
                    stateMap[cs.id].state = 'unpresent';
                    const updates = mechanics.onChallengeCleared[cs.id];
                    if (updates) {
                        applyStateUpdates(objectStates, updates, animations, lastTick);
                    }
                }
            })

            updateAnimations(animations, elapsedTime);
            lastTick = elapsedTime;


            return {
                id: scene.id,
                background: scene.background,
                gameObjects: objectStates.filter(oS => oS.state !== 'unpresent')
            };
        },

        completeChallenge(challengerId) {
            stateMap[challengerId].state = 'unpresent';
            const updates = mechanics.onChallengeCleared[challengerId];
            console.log(updates)
            if (updates) {
                applyStateUpdates(objectStates, updates, animations, lastTick);
            }
        },

        challengesRemaining: () => challengerStates.reduce((count, chall) => chall.state !== 'unpresent' ? count + 1 : count, 0),

        gameEvent: (event) => {
            switch (event.type) {
                case 'celebration':
                    for (let i = 0; i < 1; i++) {
                        const start = -10 + Math.random() * 20
                        const end = -50 + Math.random() * 100
                        const test = createGameObjectState({
                            avatar: event.object, behaviour: 'thing', challenge: null, id: 'celebration' + i,
                            startPos: { x: 50 + start, y: 100, z: 12 },
                            size: { height: 10, width: 10 }, message: null, initialState: 'active'
                        })
                        objectStates.push(test)

                        // const movement: Movement = { to: { x: 50 + end, y: -10, z: 12 }, easing: 'ease-out', speed: 0.05 }
                        const movement: Movement = { to: { x: 50 + end, y: -10, z: 12 }, easing: 'linear', speed: 0.05 }
                        animations.push(setupMove(test, movement, lastTick))
                    }
                    const tagManagerArgs = {
                        dataLayer: {
                            userId: '001',
                            userProject: 'project',
                            page: 'home'
                        },
                        dataLayerName: 'PageDataLayer'
                    }
                    TagManager.dataLayer(tagManagerArgs)


                    // const test2 = createGameObjectState({ avatar: event.object, behaviour: 'thing', challenge: null, id: 'celebration', 
                    // startPos: { x: 50, y: 50, z: 10 }, 
                    // size: { height: 10, width: 10 }, message: null, initialState: 'active' })
                    // objectStates.push(test2)
                    // const movement2: Movement = { to: { x: 60, y: 100, z: 10 }, easing: 'ease-out', speed: 0.05 }
                    // animations.push(setupMove(test2, movement2, lastTick+1020))

                    // const fade: Fade = {targetOpacity: 0, speed: 0.0005, easing: 'linear'}
                    // animations.push(setupFade(test, fade, lastTick+5))
                    break;

                default:
                    throw 'Event not Implemented';
            }
        },

        pointerEvent: (event) => {
            if (event.type === 'click' && event.target) {
                const targetState = stateMap[event.target.id];
                const targetDefinition = definitionMap[event.target.id];
                if (targetState.click.length > 0) {
                    targetState.click.forEach(toDo => {
                        switch (toDo) {
                            case 'next':
                                break;
                            case 'pick':
                                animations.push(setupMoveToPossessions(targetState, lastTick));
                                break;
                            case 'startChallenge':
                                if ('challenge' in targetDefinition && targetDefinition.challenge !== null) {
                                    actions.startChallenge(targetState.id, targetDefinition.challenge);
                                } else {
                                    console.error(`startChallenge was called on a target with no challenge definition (target ID: ${targetDefinition.id})`);
                                }
                                break;
                            case 'toggleMessage':
                                // TODO! 
                                break;
                            case 'switchScene':
                                actions.switchScene((targetState as TransitionPointState).targetScene, (targetState as TransitionPointState).targetPlayerPosition);
                                break;
                            default:
                            // Ignore
                            // TODO: Report? Error? 

                        }
                    });
                }
            }
        }
    };
}

const factories: { [key in SceneMechanics['type']]: (
    definition: SceneDefinition,
    player: PlayerDefinition,
    actions: GameActionHandlers,
    saveStateHandler: SaveStateHandler,
    loadedState?: string
) => SceneEngine } = {
    dashboard,
    assignmentRoom,
    staticSideviewLandscape
};

export const createScene: (definition: SceneDefinition, player: PlayerDefinition, actions: GameActionHandlers, saveStateHandler: SaveStateHandler, loadedState?: string) => SceneEngine = (definition, player, actions, saveStateHandler, loadedState) => {
    return factories[definition.mechanics.type](definition, player, actions, saveStateHandler, loadedState);
};

export default createScene; 