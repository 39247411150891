import Task, { TaskDefinition } from "@task";
import React, { useEffect, useState } from "react";
import styles from './FlashCard.module.scss';
import FlashCardFeedback, { FlashcardFeedbackProps, useFeedbackState } from "./FlashCardFeedback";
import { getAnswer, getQuestion } from "components/challenge/challenge.utils";
import { useEvent } from "hooks";
import { getCorrectAnswer } from "@task/TaskUtils";

export type FlashProps = {
    nrOfQuestions: number;
    getTask: () => TaskDefinition;
    onRetreat: () => void;
    onSuccess: (options?: { value: number, type: string }) => void;
}

export type FlashState = 'intro' | 'challenge' | 'finished';

export type FlashStateMachineProps = FlashProps & {
    state: FlashState;
    task?: TaskDefinition;
    questionNr?: number;
    score: number,
    currentQuestion: number,
    nrOfQuestions: number,
    nextTask: () => void;
    scoreTask: (task: TaskDefinition, score: number, correctAnswer: string) => void;
}

export const FlashCardScene = (props: FlashProps & { children: JSX.Element | JSX.Element[] }) => {
    return (<div className={styles.scene}>
        <div className={styles.sceneContent}>
            {props.children}
        </div>
    </div>)
}
export const FlashStateMachine = (props: FlashStateMachineProps) => {
    const invalidStateError = <>
        <div style={{ fontFamily: "Architects Daughter, cursive", marginTop: '20px' }}>Övningsområdet saknar frågor på vald nivå</div>
        <br />
        <button onClick={props.onRetreat}>Back</button>
    </>;
    const states: Record<FlashState, () => JSX.Element> = {
        intro: () => {
            return <>
                <FlashCardScene {...props}>
                    <h1>Flash Card Intro</h1>
                    <p>
                        <button onClick={props.nextTask}>Start</button>
                    </p>
                </FlashCardScene>
            </>
        },
        challenge: () => {
            if (!props.task) return invalidStateError;
            return <div className={styles.scene}>
                <div className={styles.sceneContent}>
                    <h1>Flash Card Challenge</h1>
                    <Task questionNr={props.questionNr} data={props.task} onCompletion={props.scoreTask} showInstructions={false}></Task>
                </div>
            </div>
        },
        finished: () => {
            return <>
                <FlashCardScene {...props}>
                    <h1>Flash Card Finished</h1>
                    <p>Score: {props.score}</p>
                    <p>Questions: {props.currentQuestion}/{props.nrOfQuestions}</p>
                    <button onClick={() => props.onSuccess()}>Next</button>
                </FlashCardScene>
            </>
        }
    }

    return states[props.state]();
}

const FlashCard = (props: FlashProps) => {
    const [state, setState] = useState<FlashState>('intro');
    const [task, setTask] = useState<TaskDefinition>();
    const [feedbackState, setFeedbackState] = useFeedbackState();
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    const [score, setScore] = useState<number>(0);
    const totalQuestions = props.nrOfQuestions ?? 5;

    const nextTask = () => {
        setState('challenge');
        setTask(props.getTask());
        setCurrentQuestion(cq => cq + 1);
    }

    const giveFeedback = (task: TaskDefinition, correctAnswer: string) => {
        console.log('Feedback');
        const question = getQuestion(task)
        const formattedCorrectAnswer = getAnswer(task, correctAnswer, 'Rätt svar: ', true, false)
        const taskComment = task.comment
        setFeedbackState({
            isActive: true,
            question: question,
            correctAnswer: formattedCorrectAnswer,
            taskComment: taskComment,
            onClick: (isCorrect) => {
                if (isCorrect) {
                    setScore(s => s + 1);
                }
                if (currentQuestion < totalQuestions) {
                    nextTask();
                } else {
                    setState('finished');
                }
            }
        });
    }

    const scoreTask = (task: TaskDefinition, score: number, correctAnswer: string) => {
        console.log('Score task', task, score, correctAnswer);
        giveFeedback(task, correctAnswer);
    }

    const handleKeyPress = (e: any) => {
        console.log('Key press', e.key);
        if (feedbackState.isActive) {
            if (e.key === 'ArrowRight') {
                feedbackState.onClick(false);
            } else if (e.key === 'ArrowLeft') {
                feedbackState.onClick(true);
            }
        } else if (!feedbackState.isActive) {
            if (e.key === 'Enter' || e.key === 'ArrowRight') {
                if (state === "intro") {
                    nextTask();
                }
                else if (state === 'finished') {
                    props.onSuccess();
                } else if (currentQuestion < totalQuestions) {
                    let correctAnswer = getCorrectAnswer(task!);                    
                    scoreTask(task!, 0, correctAnswer);
                } else {
                    setState('finished');
                }
            }
        }
    }

    useEvent('keyup', handleKeyPress);


    return <>
        <div style={{ position: 'fixed', inset: 0, backgroundColor: '#000a' }}></div>
        <div className={styles.mouseCatcher} />
        <div>Score: {score}</div>
        <div>Question: {currentQuestion}/{totalQuestions}</div>
        <div className={styles.flashCard}>
            <div>Score: {score}</div>
            <div>Question: {currentQuestion}/{totalQuestions}</div>
            <FlashStateMachine {...props} state={state} nextTask={nextTask} scoreTask={scoreTask} task={task} score={score} currentQuestion={currentQuestion} nrOfQuestions={totalQuestions} ></FlashStateMachine>
            <FlashCardFeedback {...feedbackState}></FlashCardFeedback>
            <button onClick={props.onRetreat}>Back</button>
        </div>
    </>
}

export default FlashCard;
