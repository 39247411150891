import React, { CSSProperties } from 'react'
import { TaskDefinition } from '.';
import { getCorrectAnswer as getCorrectAnswerFillGap } from './FillGaps';
import { getCorrectAnswer as getCorrectAnswerMultipleChoice } from './MultipleChoice';
import { getCorrectAnswer as getCorrectAnswerFreeText } from './FreeText';

export const WriteSentence = (props: { sentenceFragment: string, style?: CSSProperties }) => {
    const { sentenceFragment, style } = props
    const handleSegment = (seg: string) => {
        if (seg.includes('^^')) {
            return seg.split(' ').map((word, index) => {
                return word.startsWith('^^') ? <span key={index} style={{ fontSize: '300%' }}>{word.replace('^^', '')} </span> : `${word} `
            });
        }
        else return seg
    }
    const spanStyle = style !== undefined ? style : {}
    return <span style={spanStyle}>{sentenceFragment.split('||').map((seg, splitIndex, arr) =>
        <span key={splitIndex}>
            {seg !== '' && <>{handleSegment(seg)}</>}
            {splitIndex !== arr.length - 1 && <br />}
        </span>
    )}</span>
}

export const getCorrectAnswer = (task: TaskDefinition) => {
    if (task.type === 'FillGaps') {
        return getCorrectAnswerFillGap(task);
    } else if (task.type === 'MultipleChoice') {
        return getCorrectAnswerMultipleChoice(task);
    } else if(task.type === 'FreeText') {
      return getCorrectAnswerFreeText(task);
    }
    else throw new Error(`Unexpected type ${task.type}`);
}