import Control from 'components/sideMenus/Control';
import Stats from 'components/sideMenus/Stats';
import React, { useState } from 'react';
import sceneStyles from './../../components/scene/Scene.module.scss'
import styles from './InformationOverlay.module.scss'

const InformationOverlay = (props: { showOverlay: boolean, setShowOverlay: (value: boolean) => void }) => {

    const [bubbleIndex, setBubbleIndex] = useState<number>(0)

    const handleIndex = () => {
        if (bubbles.length <= bubbleIndex + 1) {
            setBubbleIndex(0)
            props.setShowOverlay(false)
        }
        else {
            // setBubbleIndex(n => ++n%bubbles.length)
            setBubbleIndex(n => ++n)
        }
    }
    const leftHanded = false;
    const bubbles = [
        { type: 'control', id: 'switch_def_img', text: 'Välj spel' },
        { type: 'control', id: 'select_node_img', text: 'Välj Övningsområde' },
        { type: 'control', id: 'logout_img', text: 'Logga ut' },
        { type: 'stats', id: 'banner_img', text: 'Visa/dölj poäng' },
        { type: 'stats', id: 'total_score', text: 'Visa totalpoäng' },
        { type: 'stats', id: 'streak', text: 'Visa längsta svit' },
        { type: 'stats', id: 'timed_mode', text: 'Testa dig själv på 60 sekunder' },
        { type: 'stats', id: '100%_mode', text: 'Testa om du kan få 100% rätt' },
    ];

    return (<>
        {props.showOverlay && <div style={{ backgroundColor: '#000a', zIndex: 10, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleIndex}>
            <div className={styles.container}>
                <Stats className={leftHanded ? sceneStyles.leftOverlay : sceneStyles.rightOverlay} score={{ score: 25, scoreInARow: 10, bestScoreInARow: 15, loginStreak: 3 }} switchGameMode={() => { }} isInformationOverlay={true} informationSpeechBubble={bubbles[bubbleIndex]} />
                <Control className={leftHanded ? sceneStyles.rightOverlay : sceneStyles.leftOverlay} skinSettingsCallback={() => { }} nodeSettingsCallback={() => { }} setShowOverlay={() => { }} isInformationOverlay={true} informationSpeechBubble={bubbles[bubbleIndex]} />
            </div>
        </div>}
    </>);
};

export default InformationOverlay;