import React from 'react';
import { ChallengeFeedbackProps, challengeFeedbackTypeProps } from "./ChallengeFeedback";
import styles from './ChallengeFeedback.module.scss';

const ContinuePlaying = (props: ChallengeFeedbackProps
    & {
        extraLives: number,
        setUseExtraLives: (value: boolean) => void,
    }
) => {

    const typeProps = challengeFeedbackTypeProps[props.type];

    if (props.type === 'incorrect' && props.extraLives && props.extraLives > 0) {
        return (<div className={`${styles.continuePlaying} ${typeProps.active ? styles.active : ''}`} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div className={styles.continuePlayingContent} >
                Du har {props.extraLives} extraliv! <br />
                Använd ett extraliv för att fortsätta spela?
                <div>
                    <button style={{margin: '5px', padding: '5px'}} onClick={() => props.setUseExtraLives(true)}>Ja</button>
                    <button style={{margin: '5px', padding: '5px'}} onClick={() => props.setUseExtraLives(false)}>Nej</button>
                </div>
            </div>
        </div>)
    }
    else return (<></>)
}

export default ContinuePlaying;