import React from 'react';
import { TaskDefinition } from "@task";
import { WriteSentence } from "@task/TaskUtils";

export const getQuestion = (task: TaskDefinition) => {
    switch (task.type) {
        case 'MultipleChoice':
            return <WriteSentence sentenceFragment={task.question} />
        case 'FillGaps':
            return ''
        // return <WriteSentence sentenceFragment={task.words.} />
        case 'FreeText':
            return '';
        default:
            return <div>Question</div>
    }
}

export const getAnswer = (task: TaskDefinition, answer: string, preamble: string, bold: boolean, italics: boolean) => {
    switch (task.type) {
        case 'MultipleChoice':
            let ans = <WriteSentence sentenceFragment={`${answer}`} />
            if (bold) { ans = <b>{ans}</b> }
            if (italics) { ans = <i>{ans}</i> }
            return <><div>{preamble} {ans}</div></>
        case 'FillGaps':
        case 'FreeText':
            const findBracketedWords = /(\[.*?\])/;
            const correctAnswerSplit = answer.split(findBracketedWords)
            return <><div>{preamble} {correctAnswerSplit.map(word => {
                if (findBracketedWords.test(word)) {
                    let ans = <WriteSentence sentenceFragment={word} />
                    if (bold) { ans = <b>{ans}</b> }
                    if (italics) { ans = <i>{ans}</i> }
                    return ans
                } else return <WriteSentence sentenceFragment={word} />
            })}
            </div></>
        default:
            return <div>Answer</div>
    }
}