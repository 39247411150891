import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';
// import ReactPixel from 'react-facebook-pixel';

const tagManagerArgs = {
  gtmId: 'GTM-5RF58VC',
  // gtmId: 'GTM-5HX5GGG', 
}

TagManager.initialize(tagManagerArgs)

// const pixelOptions = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: true, // enable logs
// };

// ReactPixel.init('641978474781916', undefined, pixelOptions);
// ReactPixel.revokeConsent();
// ReactPixel.pageView();


// fbq('consent', 'grant');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

