import React from 'react'
import styles from './statModal.module.scss'
import { Avatar } from 'components/challenge';

interface StatModalProps {
    stat: string[];
    avatar: string;
    title: string; // new prop
    onClose: () => void;
}

export const StatModal: React.FC<StatModalProps> = ({ stat, avatar, title, onClose }) => {
    return (
        <div className={styles.statModal}>
            <img className={styles.close} src='./assets/close-with-background.png' alt="X" data-testid='settings_close_button'
                onClick={close} />
            <h2 >{title}</h2>
            <div className={styles.body} >
                {/* <button className={styles.close} onClick={close}>close</button> */}
                <Avatar avatar={avatar} />
                {/* <img src={avatar} alt="Avatar" className={styles.avatar} /> */}
                {stat.map((s, index) => <p className={styles.stat} style={{ gridColumn: 2, gridRow: index + 2 }}>{s}</p>)}
            </div>
        </div>
    )

    function close() {
        console.log('close');
        onClose();
    }
}