import React, { useContext, useEffect, useState } from 'react';
import timerPng from './timer.png'
import { GameGlobalsContext } from 'contexts/GameGlobalsContext';
import { GameMode } from 'engine/game';
import { SaveStateHandler } from 'engine/saveStateHandler';
import { useStudent } from 'providers/SignInProvider';
import gameService from 'services/gameService';
import styles from './sideMenus.module.scss'
import iconScore from './score.svg'
import fireStreak from './fire-streak.svg'
import trophy from './trophy.svg'
import banner from './banner.svg'
import oneHundred from './oneHundred.svg'
import IconButton from 'ux_components/icons';
import Tooltip from 'ux_components/tooltip';
import overlayStyles from './SceneOverlay.module.scss'
import goldenArrowPng from './golden_arrow animated.png'
import { StatModal } from 'components/statsModal/statModal';

export type TimerProps = {
  seconds: number,
  setSeconds: (value: number) => void,
  isActive: boolean,
  setIsActive: (value: boolean) => void
}

export const displayTime = (timeInMS: number, startTimeInMS: number) => {
  const time = Math.round((timeInMS - startTimeInMS) / 1000)

  return time
}

export type Score = {
  score: number,
  scoreInARow: number,
  bestScoreInARow: number,
  loginStreak: number,
}

const ScoreRow = (props: {
  id: string,
  src: string,
  alt: string,
  score: string,
  clickable: boolean,
  tooltip?: string,
  informationSpeechBubble?: { id: string, text: string },
}) => {
  const iconStyle = !props.informationSpeechBubble || props.informationSpeechBubble.id === props.id ? '' : overlayStyles.iconButton
  return <Tooltip {...{ id: props.id, text: !!props.tooltip && !props.informationSpeechBubble ? props.tooltip : '', position: 'right', style: { textAlign: 'center' }, onClick: () => { } }}>
    {props.informationSpeechBubble && props.informationSpeechBubble.id === props.id && <div className={`${overlayStyles.speechBubble} ${overlayStyles.left}`} >
      <div>{props.informationSpeechBubble.text}</div>
      <img src={goldenArrowPng} alt="golden arrow" />
    </div>}
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: 'fit-content' }}>
        <IconButton class={iconStyle} {...{ id: props.id, src: props.src, alt: props.alt, large: true, clickable: props.clickable, text: props.score, }} />
      </div>
    </div>
  </Tooltip>
}

export const OneHundred = (props: { onClick: () => void, informationSpeechBubble?: { id: string, text: string } }) => {
  const student = useStudent()
  const [score, setScore] = useState<number>()

  useEffect(() => {
    let running = true
    if (score === undefined && student && student.id) {
      setScore(0)
      if (running) {
        gameService.getOneHundredModeScore(student.id.toString()).then(result => {
          const mode = result['mode'] ?? { 'score': 0 }
          const score = mode['score'] ?? 0
          const questionLimit = mode['questionLimit'] ?? 0
          console.log('score', score, 'questionLimit', questionLimit)
          setScore(questionLimit !== 0 ? score / questionLimit * 100 : 0)
        }).catch(e => {
          console.error('error', e)
          setScore(0)
        })
      }
    }
    return () => { running = false }
  }, [score, student])

  return <>
    <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={props.onClick} >
      <ScoreRow {...{ id: '100%_mode', src: oneHundred, alt: 'oneHundred', score: `${score}%` ?? '0', tooltip: 'Testa dig själv', clickable: true, informationSpeechBubble: props.informationSpeechBubble }} />
    </div>
  </>
}

export const StopTimer = (props: { onClick: () => void, informationSpeechBubble?: { id: string, text: string } }) => {

  const student = useStudent()
  const gameGlobals = useContext(GameGlobalsContext);
  const [score, setScore] = useState<number>()

  useEffect(() => {
    let running = true
    if (score === undefined && student && student.id) {
      setScore(0)
      if (running) {
        gameService.getTimedModeScore(student.id.toString()).then(result => {
          const mode = result['mode'] ?? { 'score': 0 }
          const score = mode['score'] ?? 0
          setScore(score)
        }).catch(e => {
          console.error('error', e)
          setScore(0)
        })
      }
    }
    return () => { running = false }
  }, [score, student])

  return <>
    <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={props.onClick} >
      <ScoreRow {...{ id: 'timed_mode', src: timerPng, alt: 'timer', score: `${score}` ?? '0', tooltip: 'Snabbspel', clickable: true, informationSpeechBubble: props.informationSpeechBubble }} />
    </div>
    {gameGlobals.gameMode?.isTimed && <><br /><div>{displayTime(gameGlobals.timer ?? 0, gameGlobals.gameMode.startTime)}</div></>}
  </>
}

const checkReload = () => {
  const test = window.sessionStorage.getItem('reload');
  window.sessionStorage.removeItem('reload');
  return test === "true";
}

function Stats(props: {
  className: string,
  score: Score
  saveStateHandler?: SaveStateHandler,
  isInformationOverlay?: boolean,
  informationSpeechBubble?: { id: string, text: string },
  switchGameMode: (newMode: GameMode) => void
}) {
  const student = useStudent()

  const [show, setShow] = useState(true)
  const [showStats, setShowStats] = useState(true);

  const gameGlobals = useContext(GameGlobalsContext);

  useEffect(() => {
    const isReloaded = checkReload()
    if (isReloaded === true) {
      setShowStats(false)
    }
  },[])

  const displayTime = () => {
    const time = gameGlobals.timer ?? 0
    return time !== 0 ? `${Math.floor(time / 60000)}.${Math.floor(((time) % 60000) / 1000)}` : '0'
  }

  const toTimed = async () => {
    const gameDef = await gameService.getGameDefinition('timed', 'default');
    props.saveStateHandler?.saveScene({ adventureId: gameDef.adventure, sceneId: gameDef.scenes[0].id, theme: gameDef.theme }, true).then(result =>
      window.location.reload()
    )
  }

  return (
    <div className={props.className} style={{ textAlign: 'center' }}>
      <div id='banner_div' className={`${styles.statsNamePlate} ${styles.hover} ${styles.hover_banner}`} onClick={props.isInformationOverlay ? () => { } : () => setShow(s => !s)}>
        {props.informationSpeechBubble?.id === 'banner_img' && <div className={`${overlayStyles.speechBubble} ${overlayStyles.left} ${overlayStyles.banner}`} >
          <div>{props.informationSpeechBubble.text}</div>
          <img src={goldenArrowPng} alt="golden arrow" />
        </div>}

        <img id='banner_img' src={banner} alt='banner' className={``} />
        <div id='banner_name' className={styles.bannerText}>{student?.playerSettings?.nickname}</div>
      </div>
      {<div className={show ? (styles.scores + ' ' + styles.show) : styles.scores} style={props.isInformationOverlay ? { zIndex: 0 } : {}}>
        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => props.isInformationOverlay ? () => { } : alert(`Du har ${props.score.score} poäng!`)}>
          <ScoreRow {...{ id: 'total_score', src: trophy, alt: 'trophy', score: `${props.score.score}`, clickable: true, informationSpeechBubble: props.informationSpeechBubble }} />
        </div>
        {/* <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => props.isInformationOverlay ? () => { } : alert(`Din längsta "streak" är ${props.score.bestScoreInARow} rätta svar i rad!`)}>
          <ScoreRow {...{ id: 'streak', src: fireStreak, alt: 'fireStreak', score: `${props.score.bestScoreInARow}`, clickable: true, informationSpeechBubble: props.informationSpeechBubble }} />
        </div> */}
        <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => props.isInformationOverlay ? () => { } : setShowStats(s => !s)}>
          <ScoreRow {...{ id: 'streak', src: fireStreak, alt: 'fireStreak', score: `${props.score.bestScoreInARow}`, clickable: true, informationSpeechBubble: props.informationSpeechBubble }} />
        </div>
        {showStats && <StatModal stat={[`${props.score.loginStreak} ${props.score.loginStreak === 1 ? 'dag' : 'dagar'} i rad`, `${props.score.bestScoreInARow} ${props.score.bestScoreInARow === 1 ? 'korrekt' : 'korrekta'} svar i rad`]} avatar="knight_running" title="Dina sviter" onClose={() => setShowStats(false)} />}
        <StopTimer onClick={props.isInformationOverlay ? () => { } : () => props.switchGameMode({ type: 'Timed', isTimed: true, startTime: gameGlobals.timer ?? 0, timeLimit: 60 })} informationSpeechBubble={props.informationSpeechBubble} />

        <OneHundred onClick={props.isInformationOverlay ? () => { } : () => props.switchGameMode({ type: 'OneHundred', isTimed: false, })} informationSpeechBubble={props.informationSpeechBubble} />
        {/* <div>Speltid: <br /> {displayTime()} min</div> */}
      </div>}
    </div>
  );
}

export default Stats;
